import { EdgeType } from '../wfe.constants'
import { createEdge, createNewNodeStep, generateUniqueNodeId } from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { NodeStrategy } from './wfNodeStrategy'

export class ExitFlowControlNodeStrategy implements NodeStrategy {
  addNode(wfModel: WorkflowModel, targetPlaceholderId: string, newNodeData: any): boolean {
    const graph = wfModel.getGraph()

    // Find placeholder node neighbor so we could:
    // 1. Remove Edge from placeholder to next edge
    // 2. Add Edge from target node new placeholder to the neighbor node
    let targetNodeChildId = undefined

    const placeholderNodeNeighbors = graph.outNeighbors(targetPlaceholderId)
    targetNodeChildId = placeholderNodeNeighbors[0]
    if (targetNodeChildId) {
      // Remove Edge from placeholder to next edge
      graph.dropEdge(targetPlaceholderId, targetNodeChildId)
    }

    // Add the new node and related structure after the target placeholder node
    // On Exit flow control node we don't need to add additional placeholder node as the flow finishes after it hits the Exit node.
    const newStepUniqueNodeId = generateUniqueNodeId(newNodeData.subtype)
    const newNodes = [createNewNodeStep(newStepUniqueNodeId, newNodeData, newNodeData.type)]
    wfModel.addNodesToGraph(newNodes)

    let newEdges = [createEdge(EdgeType.Workflow, targetPlaceholderId, newStepUniqueNodeId)]

    if (targetNodeChildId) {
      newEdges = newEdges.concat([createEdge(EdgeType.Workflow, newStepUniqueNodeId, targetNodeChildId)])
    }
    wfModel.addEdgesToGraph(newEdges)

    return true
  }

  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    // Taking advantage of graphology library dropNode action which removes a node and all of its edges,
    // 1. We will first want to take note of the parent node of the target node
    // 2. (If exists) the child node of the target node placeholder child.
    // 3. We will remove the target node
    // 4. We will connect the parent of the target node and the child of the target node

    // Retrieving parent and child
    const graph = wfModel.getGraph()
    const placeholderParent = graph.inNeighbors(nodeId)[0] // there can only be one
    const targetNodeChild = graph.outNeighbors(nodeId)[0] // there can only be one

    graph.dropNode(nodeId)

    if (targetNodeChild) {
      wfModel.addEdgesToGraph([createEdge(EdgeType.Workflow, placeholderParent, targetNodeChild)])
    }

    return true
  }
}
