import { WorkflowModel } from '../wfe.model'
import { NodeData } from '../wfe.types'
import { BasicNodeStrategy } from './wfBasicNodeStrategy'

// Define the strategy classes for each node type
export class BasicFlowControlNodeStrategy extends BasicNodeStrategy {
  addNode(wfModel: WorkflowModel, nodeId: string, nodeData: NodeData): boolean {
    return super.addNode(wfModel, nodeId, nodeData)
  }
  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    return super.removeNode(wfModel, nodeId)
  }
}
