import * as Sentry from '@sentry/react'

export const wivCaptureException = (error: any) => {
  if (window.location?.hostname === 'localhost') {
    console.error(error)

    return
  }
  const errorString = typeof error === 'string' ? error : JSON.stringify(error)
  Sentry.captureException(new Error(errorString))
}
