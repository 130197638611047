import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Workflow } from 'src/common/types/common.types'

export const WorkflowDeleteDialog = ({
  deletedWorkflow,
  onDelete,
  onClose
}: {
  deletedWorkflow: Workflow | null
  onDelete: () => void
  onClose: () => void
}) => {
  return (
    <Dialog open={!!deletedWorkflow} onClose={onClose}>
      <DialogTitle>Delete workflow</DialogTitle>
      <DialogContent>Are you sure you want to delete workflow '{deletedWorkflow?.name}'?</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant='contained'
          onClick={async () => {
            await onDelete()
            onClose()
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
