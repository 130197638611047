import { useUser } from '@propelauth/nextjs/client'
import { useMemo } from 'react'
import get from 'lodash/get'
import { routes } from '../constants/routes.constants'
import { Permission } from './constants'

export const useUserPermissions = () => {
  const { user } = useUser()
  const currentOrg = useMemo(() => user?.orgIdToOrgMemberInfo?.[user?.activeOrgId || ''], [user])

  return currentOrg?.userPermissions || []
}

export const useCanPerformAction = (action?: Permission) => {
  const permissions = useUserPermissions()

  return !action || !!permissions.includes(action)
}

// /integrations/AWS -> integrations
const getPage = (route: string) => route.split('/')[1]
export const useAccessPage = (pathname: string) => {
  const path = getPage(pathname)
  const permission = get(routes, path)?.permission
  const canAccess = useCanPerformAction(permission)
  const { user } = useUser()

  if (!user) {
    return true
  }

  return canAccess
}
