const Dialog = () => {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialogTitle-root': {
            fontSize: '22px !important',
            fontWeight: 700,
            color: 'var(--darkBlue)',
            background: '#F7F6FF',
            borderBottom: '1px solid #C8CDDC',
            boxShadow: '0px 0px 3px inset #C8CDDC'
          },
          '& .MuiDialogActions-root': {
            borderTop: '1px solid #DEDBEF'
          },

          '& .MuiDialogContent-root': {
            paddingTop: '16px !important'
          },
          '& .cancel': {
            cursor: 'pointer',
            fontSize: '14px',
            color: 'var(--primaryBlueBrand)'
          }
        }
      }
    }
  }
}

export default Dialog
