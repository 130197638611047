import React, { useEffect, useMemo, useRef } from 'react'
import { shallow } from 'zustand/shallow'
import cx from 'classnames'
import Text from '../Text'
import { IconsPropsByStatus } from '../../common/utils/workflows'
import ArrowExpander from '../ArrowExpander'
import RunningWorkflowDetails from '../RunningWorkflowDetails'
import { WithHoverVariants } from '../WithHover'
import useStore from '../../modules/wfe/wfe.store'
import { WorkflowExecutionStatus } from '../../modules/wfe/wfe.constants'
import { SingleStepExecutionsStatuses } from '../../modules/wfe/wfe.types'
import styles from './index.module.css'
import { getSummaryExecutionsStatus, getSummaryExecutionTitle, isErrorMessage } from './utils'

const selector = (state: any) => ({
  stepExecutions: state.stepExecutions,
  workflowExecution: state.workflowExecution,
  isWorkflowExecutionOpened: state.isWorkflowExecutionOpened,
  setIsWorkflowExecutionOpened: state.setIsWorkflowExecutionOpened,
  clearStepExecutionDisplay: state.clearStepExecutionDisplay,
  updateWorkflowExecution: state.updateWorkflowExecution,
  isShowExecutionSummary: state.isShowExecutionSummary,
  setIsShowExecutionSummary: state.setIsShowExecutionSummary
})

const ExecutionSummaryDropdown = () => {
  const {
    workflowExecution,
    stepExecutions,
    isWorkflowExecutionOpened,
    setIsWorkflowExecutionOpened,
    clearStepExecutionDisplay,
    updateWorkflowExecution,
    isShowExecutionSummary,
    setIsShowExecutionSummary
  } = useStore(selector, shallow)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const isRunningSteps = workflowExecution?.status === WorkflowExecutionStatus.RunningSteps
  const runningSteps = isRunningSteps
    ? Object.entries(stepExecutions)
        .filter(([, execution]: any) => execution?.displayStep)
        .map(([title, execution]: any) => ({ title, ...execution }))
    : workflowExecution.executedSteps.map((eStep: any) => ({ ...eStep, title: eStep.id }))

  const summaryStatus = useMemo(() => getSummaryExecutionsStatus(runningSteps), [runningSteps])
  const status = isRunningSteps ? summaryStatus : workflowExecution.status
  const title = getSummaryExecutionTitle(isRunningSteps, status) || ''

  const singleStepsStatus = useMemo(() => {
    if (!isRunningSteps) {
      return undefined
    }

    const steps = Object.keys(stepExecutions).filter(step => stepExecutions[step]?.displayStep)

    if (
      steps.some(
        (stepId: string) =>
          stepExecutions[stepId].status === WorkflowExecutionStatus.Running ||
          stepExecutions[stepId].status === WorkflowExecutionStatus.Waiting
      )
    ) {
      return WorkflowExecutionStatus.Running
    }

    if (steps.some((stepId: string) => stepExecutions[stepId].status === SingleStepExecutionsStatuses.FAIL)) {
      return WorkflowExecutionStatus.Failed
    }

    if (steps.every((stepId: string) => stepExecutions[stepId].status === SingleStepExecutionsStatuses.OK)) {
      return WorkflowExecutionStatus.Succeeded
    }
  }, [isRunningSteps, stepExecutions])

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    if (isRunningSteps && singleStepsStatus !== WorkflowExecutionStatus.Running) {
      timerRef.current = setTimeout(() => {
        clearStepExecutionDisplay()
        setIsWorkflowExecutionOpened(false)
        updateWorkflowExecution(false)
        setIsShowExecutionSummary(true)
      }, 8000)
    }
  }, [
    setIsShowExecutionSummary,
    setIsWorkflowExecutionOpened,
    singleStepsStatus,
    isRunningSteps,
    isWorkflowExecutionOpened,
    clearStepExecutionDisplay,
    updateWorkflowExecution
  ])

  const isDisplayArrow = !!runningSteps?.length

  return (
    <div className={cx(styles.ExecutionSummaryDropdown, { [styles.marginRight]: !isDisplayArrow })}>
      <Text
        maxLength={40}
        className={styles.line}
        title={title}
        iconProps={{
          ...IconsPropsByStatus[status],
          height: 24,
          width: 24
        }}
      />
      {isDisplayArrow && (
        <ArrowExpander
          isExternalShowHoverComponent={isShowExecutionSummary}
          setIsExternalShowHoverComponent={setIsShowExecutionSummary}
          withHoverProps={{
            gap: 20,
            ComponentToShow: (
              <RunningWorkflowDetails
                runningWorkflowData={runningSteps}
                loopStatus={workflowExecution.loopStatus}
                errorMessage={isErrorMessage(workflowExecution.errorMessage) && workflowExecution.errorMessage}
              />
            ),
            variant: WithHoverVariants.RIGHT
          }}
        />
      )}
    </div>
  )
}

export default ExecutionSummaryDropdown
