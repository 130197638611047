import { UNPUBLISHED } from 'src/common/types/common.types'
import { Template } from 'src/modules/templates/type'
import { request } from './api'

export async function fetchDefaultWorkflowTemplates() {
  const response = await request(`default-workflow-templates`)

  return response.data.map((workflowTemplate: { state: any }) => ({
    ...workflowTemplate,
    state: workflowTemplate.state ?? UNPUBLISHED
  }))
}

export async function fetchDefaultWorkflowTemplate(workflowTemplateUuid: string) {
  const response = await request(`default-workflow-templates/${workflowTemplateUuid}`)

  return response
}

export async function fetchWorkflowTemplates() {
  const response = await request(`workflow-templates`)

  return response.data
}

export async function fetchWorkflowTemplate(workflowTemplateUuid: string) {
  const response = await request(`workflow-templates/${workflowTemplateUuid}`)

  return response
}

export async function createWorkflowTemplate(template: Template) {
  const response = await request(`workflow-templates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(template)
  })

  return response
}

export async function deleteWorkflowTemplate(templateId: string) {
  const response = await request(`workflow-templates/${templateId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return response
}
