import { useEffect } from 'react'

export const useMouseClickOutside = (ref: any, onOutsideClick: any, type = 'click') => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick(event)
      }
    }
    document.addEventListener(type, handleClickOutside)

    return () => {
      document.removeEventListener(type, handleClickOutside)
    }
  }, [ref, onOutsideClick])
}
