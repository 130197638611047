import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'

import { Box } from '@mui/material'
import { useState } from 'react'
import { useNavigationLinks } from '../navigation/vertical'
import styles from './styles.module.css'

export const NavBar = () => {
  const navItems = useNavigationLinks()
  const router = useRouter()
  const [clickedTab, setClickedTab] = useState('')

  return (
    <div className={styles.navBar}>
      {navItems.map(navItem => {
        const { title, path, icon } = navItem

        const NavIcon = icon

        const isClicked = clickedTab.includes(path)
        const isActive = router.pathname.includes(path)

        return (
          <Link key={title} href={path ?? ''}>
            <Box
              className={cx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.linkClicked]: isClicked
              })}
              sx={{ '&:hover *': { color: '#EE2C79' } }}
              onClick={() => setClickedTab(path)}
            >
              <NavIcon />
              <div className={styles.linkText}>{title}</div>
            </Box>
          </Link>
        )
      })}
    </div>
  )
}
