import { Theme } from '@mui/material/styles'

const getTextStyle = (theme: Theme) => {
  return {
    borderRadius: '4px !important',
    'fieldset, & + fieldset': {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '4px !important',
      legend: {
        '&:not(.Mui-disabled)': {
          color: `${theme.palette.grey[500]} !important`
        }
      }
    },
    'fieldset legend, & + fieldset legend': { width: 0 },
    label: {
      '&:not(.Mui-disabled)': {
        color: `${theme.palette.grey[500]} !important`
      }
    }
  }
}

const Input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          marginBottom: '4px'
        }
      }
    },

    MuiFormControl: {
      styleOverrides: {
        root: getTextStyle(theme)
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: getTextStyle(theme)
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: getTextStyle(theme)
      }
    }
  }
}

export default Input
