import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import NProgress from 'nprogress'

export const UnsavedDialog = ({
  open,
  onClose,
  nextNavigationUrl
}: {
  open: boolean
  onClose: () => void
  nextNavigationUrl: string
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        Are you sure you want to leave this page?
        <br />
        Changes you made will not be saved.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            NProgress.done()
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={async () => {
            onClose()
            location.replace(nextNavigationUrl)

            //bypass router events
          }}
        >
          Leave Page
        </Button>
      </DialogActions>
    </Dialog>
  )
}
