import { compact } from 'lodash'
import { containsOnlyPrimitives } from '../StepBar/OutputTreeComponents'

const isPrimitive = (obj: any) => typeof obj === 'string' || typeof obj === 'number'

export const filterTreeData = (node: any, searchText: string): any => {
  if (!searchText) return node

  if (!node) return null
  if (isPrimitive(node) && String(node).toLowerCase().includes(searchText)) {
    return node
  }

  if (Array.isArray(node)) {
    const filteredChildren = compact(node.map(childNode => filterTreeData(childNode, searchText)))

    return filteredChildren.filter(childNode => {
      return (Array.isArray(node) && !!compact(childNode?.length)) || (typeof childNode === 'object' && node !== null)
    })
  }

  if (typeof node === 'object' && node !== null) {
    let hasMatchingChild = false
    const filteredNode: Record<string, any> = {}

    for (const key in node) {
      if (
        key.toLowerCase().includes(searchText) ||
        (isPrimitive(node[key]) && String(node[key]).toLowerCase().includes(searchText))
      ) {
        filteredNode[key] = node[key]
        hasMatchingChild = true
      } else if (node[key] !== null && typeof node[key] === 'object') {
        const filteredChild = filterTreeData(node[key], searchText)

        if (filteredChild && (!Array.isArray(filteredChild) || filteredChild.length)) {
          filteredNode[key] = filteredChild
          hasMatchingChild = true
        }
      }
    }

    return hasMatchingChild ? filteredNode : null
  }

  return null
}

const parsePathString = (pathString: string) => {
  const regex = /([^[\]]+)|(\[\d+\])/g
  const matches = pathString.match(regex)

  return matches ? matches : []
}

// suggestions.Coool_step.output[1][1][0] -> suggestions.Coool_step.output[1].config.label.labelId
export const transformLoopPath = (path: string, value: any, label: string, initialData: any) => {
  let initialPath = path
  if (typeof value === 'object' || path !== value) {
    initialPath = path + '.' + label
  }

  let result = ''
  let restData = { ...initialData }
  initialPath
    .split('.')
    .filter(Boolean)
    .forEach(partPath => {
      if (result[result.length - 1] !== '.' && result) {
        result += '.'
      }
      if (restData[partPath]) {
        restData = restData[partPath]
        result += partPath + '.'
      } else {
        // ['output', '[1]', ['1', '0']]
        const [outputField, outputIndex, ...parts] = parsePathString(partPath)
        result += outputField
        restData = restData[outputField]

        if (outputIndex && !parts.length) {
          result += outputIndex
        } else if (outputIndex) {
          if (typeof restData === 'object' && !Array.isArray(restData)) {
            const someKey = Object.keys(restData)[0]

            restData = restData[someKey]
            result += '.' + someKey
          } else {
            restData = restData[outputIndex.replace('[', '').replace(']', '')]
            const someKey = Object.keys(restData)[0]

            restData = restData[someKey]
            result += outputIndex + '.' + someKey
          }
        }

        parts.forEach(initialPart => {
          const part = initialPart.replace('[', '').replace(']', '')
          if (part && restData[part]) {
            restData = restData[part]

            if (Array.isArray(restData)) {
              result += '.' + part
            }
          } else {
            const currentKey = Object.keys(restData)[0]

            restData = restData[currentKey]
            result += '.' + currentKey
          }
        })
      }
    })

  if (result[result.length - 1] === '.') {
    result = result.slice(0, result.length - 1)
  }

  return result
}

const convertToArray = (obj: any) => {
  const result = [] as any[]
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      result.push({ [key]: convertToArray(obj[key]) })
    } else {
      result.push(key)
    }
  }

  return result
}
const mergeObject = (obj: any, target: any) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      target[key] = target[key] || {}
      mergeObject(obj[key], target[key])
    } else if (typeof target === 'object') {
      target[key] = true
    }
  })
}

const mergeFieldsRecursive = (arr: any[]) => {
  const superset = {} as any

  if (containsOnlyPrimitives(arr)) {
    return undefined
  }

  Array.isArray(arr) &&
    arr.forEach(item => {
      if (typeof item === 'object') {
        mergeObject(item, superset)
      } else {
        superset[item] = item
      }
    })

  return convertToArray(superset)
}

const mapSteps = (steps: any = {}) => {
  return Object.keys(steps).reduce((acc, key) => {
    const value = steps[key]
    if (value?.subtype === 'LOOP' && value?.output?.length >= 1) {
      acc[key] = {
        output: mergeFieldsRecursive(value.output),
        subtype: value.subtype
      }
    } else {
      acc[key] = value
    }

    return acc
  }, {} as any)
}

export const transformTreeData = (data: any) => {
  if (typeof data !== 'object') {
    return data?.toString()
  }
  if (Array.isArray(data)) {
    return data
  }
  const result = { ...data }

  if (result.steps) {
    result.steps = mapSteps(data.steps)
  }
  if (result.suggestions) {
    result.suggestions = mapSteps(data.suggestions)
  }

  return result
}
