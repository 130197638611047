import { UniqueIdProvider } from 'src/common/utils/uniqueIdProvider.module'
import { NodeSubType, StepGroupType, StepSubGroupType, StepType } from 'src/common/constants/common.constants'
import { createNodeDataStep, getNodeScopeByNodeSubtype, getStepActionForUI, getStepParametersInput } from './wfe.helper'
import { Parameter, Step } from './wfe.types'

export class StepAction implements Step {
  public parameters: Parameter[]
  public name: string
  public title: string
  public originalName?: string
  public id?: string

  constructor(
    public group: StepGroupType,
    public subgroup: StepSubGroupType,
    public stepTemplateId: string,
    title: string,
    public description: string,
    public type = StepType.Action,
    name?: string,
    parameters?: Parameter[],
    id?: string
  ) {
    this.title = title || getStepActionForUI(subgroup, stepTemplateId)?.title || ''

    this.parameters =
      parameters ||
      (getStepParametersInput(this.stepTemplateId)?.map((input: any) => ({
        id: input.id,
        value: input.defaultValue ?? ''
      })) as Parameter[])

    this.name = name || UniqueIdProvider.getInstance().generateId(`${this.title.replace(/\d+$/, '')}`)
    this.id = id
    this.originalName = name
  }

  public clone({ duplicate }: { duplicate?: boolean }) {
    const obj = new StepAction(
      this.group,
      this.subgroup,
      this.stepTemplateId,
      this.title,
      this.description,
      this.type,
      duplicate ? this.name : '',
      this.parameters,
      this.id
    )

    return obj
  }

  public createStepNode({ duplicate }: { duplicate?: boolean } = {}) {
    const action = this.clone({ duplicate })
    const subtype = action.type === StepType.Action ? NodeSubType.BasicAction : (action.subgroup as NodeSubType)

    return createNodeDataStep(action, action.type, subtype, getNodeScopeByNodeSubtype(subtype))
  }

  public pasteStepNode() {
    return this.createStepNode({ duplicate: true })
  }
}
