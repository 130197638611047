import { parse, format } from 'date-fns'

const dateBEFormat = 'yyyy-MM-dd-HH:mm:ss'
const dateFEFormat = 'MMMM dd, yyyy, h:mm a'

// 2024-03-26-18:08:46 format date
const isoRegex = /^\d{4}-\d{2}-\d{2}-\d{2}:\d{2}:\d{2}$/
export const isDateFormatValid = (date?: string) => {
  if (!date) {
    return false
  }

  return isoRegex.test(date)
}

export const getFormattedDate = (date?: string) => {
  if (!isDateFormatValid(date)) {
    return date
  }
  const parsedDate = parse(date!, dateBEFormat, new Date())
  const formattedDate = format(parsedDate, dateFEFormat)

  return formattedDate
}
