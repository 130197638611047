import { Alert, Link, Snackbar } from '@mui/material'
import { SNACKBAR_PROPS } from 'src/common/constants/ui-components.constants'
import { Workflow } from 'src/common/types/common.types'
import { getSeverityLevelByMessage } from 'src/common/utils/error.util'

export const ShowMessage = ({
  clonedWorkflow,
  message,
  setMessage
}: {
  message: string
  clonedWorkflow: Workflow | null
  setMessage: (message: string) => void
}) => (
  <Snackbar open={!!message} onClose={() => setMessage('')} {...SNACKBAR_PROPS}>
    <Alert variant='filled' severity={getSeverityLevelByMessage(message)} onClose={() => setMessage('')}>
      {message}
      {clonedWorkflow && (
        <>
          {' '}
          - <Link href={`/wfe/${clonedWorkflow.workflowId}`}>{clonedWorkflow.name}</Link>
        </>
      )}
    </Alert>
  </Snackbar>
)
