import Graph from 'graphology'

export const findNodesWithAttribute = (
  graph: Graph,
  startNodeId: string,
  targetAttribute: string,
  targetValue: string
) => {
  const visited = new Set()
  const queue = [startNodeId]
  const traversedNodes = []
  let attributeNode = null

  while (queue.length > 0) {
    const currentNodeId = queue.shift()
    if (visited.has(currentNodeId)) {
      continue
    }

    visited.add(currentNodeId)

    // node ID to traversedNodes only if it's not the start node ID
    traversedNodes.push(currentNodeId)

    const currentNodeAttributes = graph.getNodeAttributes(currentNodeId)
    if (currentNodeAttributes.data[targetAttribute] === targetValue) {
      attributeNode = currentNodeId
    } else {
      const outNeighbors = graph.outNeighbors(currentNodeId)
      queue.push(...outNeighbors)
    }
  }

  // Return the traversed nodes
  return { attributeNode, traversedNodes }
}
