import { useState, useEffect, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useUser } from '@propelauth/nextjs/client'
import { useTheme } from '@mui/material/styles'
import toLower from 'lodash/toLower'
import sortBy from 'lodash/sortBy'
import styles from './OrganizationDropdown.module.css'

const OrganizationDropdown = () => {
  const { user, setActiveOrg } = useUser()
  const theme = useTheme()
  const [org, setOrg] = useState<string | undefined>(undefined)

  const [organizations, setOrganizations] = useState<{ [x: string]: string }>({})

  const orgList = useMemo(() => {
    const list = Object.keys(organizations).map(id => ({ id, name: organizations[id] }))

    return sortBy(list, obj => toLower(obj.name))
  }, [organizations])

  useEffect(() => {
    const orgs = user?.orgIdToOrgMemberInfo || {}
    if (!isEmpty(orgs)) {
      const newOrganizations = Object.keys(orgs).reduce((acc, orgId) => ({ ...acc, [orgId]: orgs[orgId].orgName }), {})

      // fix random propelauth refetching organization options
      if (!isEqual(newOrganizations, organizations)) {
        setOrganizations(newOrganizations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!org && orgList.length) {
      const activeOrgId = user?.activeOrgId || orgList[0]?.id
      setOrg(activeOrgId)
    }
  }, [user, orgList, org])

  const onChange = (event: SelectChangeEvent) => {
    const newActiveOrgId = event.target.value
    if (newActiveOrgId && user?.orgIdToOrgMemberInfo?.[newActiveOrgId]) {
      setOrg(newActiveOrgId)
      setActiveOrg(newActiveOrgId)
    }
  }

  if (!org) {
    return null
  }

  if (orgList.length === 1) {
    return <div className={styles.singleOrganization}>{orgList[0].name}</div>
  }

  return (
    <Select
      sx={{
        marginRight: '12px',
        '& .MuiSelect-select': {
          backgroundColor: 'white',
          borderRadius: '30px !important',
          padding: '4px 32px 4px 12px'
        },
        '& fieldset': {
          borderRadius: '30px !important',
          border: 'none'
        },
        '& .MuiBox-root': {
          color: theme.palette.customColors.brandBlueLight,
          fontSize: '14px',
          padding: 0,
          fontWeight: 800
        }
      }}
      value={org}
      onChange={onChange}
    >
      {orgList.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          <Box
            sx={{
              py: 1,
              px: 2
            }}
          >
            {name}
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}

export default OrganizationDropdown
