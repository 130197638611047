import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { useWorkflowsNames } from 'src/modules/wfe/hooks/useWorkflowsNames'
import Text from '../../../../../components/Text'
import { strings } from '../../../../../common/constants/strings'
import useStore from '../../../wfe.store'

const selector = (state: any) => ({
  workflow: state.workflow,
  setWorkflowName: state.setWorkflowName,
  activeWorkflowVersion: state.activeWorkflowVersion
})

const i18n = strings.wfeHeader

type WFNameProps = {
  hasWorkflowNameError: boolean
  setHasWorkflowNameError: (value: boolean) => void
  isEditable: boolean
}
const WFName = ({ hasWorkflowNameError, setHasWorkflowNameError, isEditable }: WFNameProps) => {
  const { workflow, setWorkflowName, activeWorkflowVersion } = useStore(selector, shallow)

  const [initialWorkflowName] = useState(workflow.name)
  const [textFieldValue, setTextFieldValue] = useState<string>(workflow?.name)

  const workflowNames: Array<string> = useWorkflowsNames()

  useEffect(() => {
    setTextFieldValue(workflow?.name)
  }, [workflow?.name])

  const onChangeWorkflowName = (newValue: string) => {
    const hasNewWorkflowNameError = Boolean(
      newValue &&
        newValue !== initialWorkflowName &&
        workflowNames.find((workflowName: string) => workflowName === newValue)
    )

    if (hasNewWorkflowNameError !== hasWorkflowNameError) {
      setHasWorkflowNameError(hasNewWorkflowNameError)
    }
    setWorkflowName(newValue)
  }

  return (
    <Text
      title={textFieldValue}
      onChange={onChangeWorkflowName}
      debounceTime={0}
      isEditable={isEditable && !activeWorkflowVersion}
      errorMessage={hasWorkflowNameError ? i18n.errorMessage : undefined}
      color='var(--primaryBlueBrand)'
      maxLength={40}
    />
  )
}

export default WFName
