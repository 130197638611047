import React, { memo, useState } from 'react'
import classNames from 'classnames'
import { Tooltip, TooltipProps } from '@mui/material'
import withHover, { WithHoverProps } from '../WithHover'
import styles from './index.module.css'

const DISABLED_COLOR = 'var(--neutralBrandBlue20)'
const DEFAULT_COLOR = 'var(--neutralBrandBlue40)'

export type IconProps = {
  className?: string
  onClick?: (e?: any) => void
  onIconClick?: (e?: any) => void
  width?: number
  height?: number
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  color?: string
  hoverColor?: string
  name?: string
  marginRight?: number | string
  marginTop?: number | string
  marginBottom?: number | string
  marginLeft?: number | string
  alt?: string
  path?: string
  withHoverProps?: WithHoverProps
  iconRef?: any
  hoverBackgroundColor?: string
  backgroundColor?: string
  backgroundHeight?: number | string
  backgroundWidth?: number | string
  tooltipProps?: {
    title: string
    placement?: TooltipProps['placement']
  }
  onMouseEnter?: (e?: any) => void
  onMouseLeave?: (e?: any) => void
  isDisabled?: boolean
  innerClassName?: string
  borderRadius?: string | number
}

const Icon = memo(
  ({
    borderRadius,
    className = '',
    onClick,
    width = 20,
    height = 20,
    style = {},
    name,
    color,
    hoverColor,
    marginRight,
    marginTop,
    marginBottom,
    marginLeft,
    alt,
    path,
    withHoverProps,
    iconRef,
    backgroundColor,
    hoverBackgroundColor,
    backgroundHeight,
    backgroundWidth,
    tooltipProps,
    onMouseEnter,
    onMouseLeave,
    containerStyle,
    isDisabled,
    onIconClick,
    innerClassName
  }: IconProps) => {
    const [isHovering, setIsHovering] = useState(false)
    const src = path || (name?.includes('/') ? `/images/${name}.svg` : `/images/svg/${name}.svg`)
    const IconComponent =
      hoverColor || color || isDisabled ? (
        <div
          className={classNames({
            [styles.clickable]: !isDisabled && (!!onClick || onIconClick),
            [styles.disabled]: isDisabled
          })}
          style={{
            marginRight,
            ...style
          }}
          onMouseEnter={() => (hoverColor || hoverBackgroundColor) && setIsHovering(true)}
          onMouseLeave={() => isHovering && setIsHovering(false)}
          ref={iconRef}
        >
          <span
            onClick={!isDisabled ? onIconClick : undefined}
            className={styles.maskIcon}
            style={{
              // @ts-ignore
              '--mask-image': `url(${src})`,
              backgroundColor: isDisabled ? DISABLED_COLOR : (isHovering && hoverColor) || color || DEFAULT_COLOR,
              width,
              height,
              minHeight: height
            }}
          />
        </div>
      ) : (
        <img
          onClick={!isDisabled ? onIconClick : undefined}
          src={src}
          alt={alt || name}
          height={height}
          width={width}
          style={{ height, width, ...style }}
          ref={iconRef}
          className={innerClassName}
        />
      )

    const IconWithHover =
      withHoverProps?.isShowHoverComponent &&
      withHover({
        ...withHoverProps,
        ComponentToHover: IconComponent
      })

    return (
      <Tooltip title={tooltipProps?.title} placement={tooltipProps?.placement || 'bottom'}>
        <div
          className={classNames(className, styles.iconWrapper, {
            [styles.clickable]: !isDisabled && !!onClick,
            [styles.disabled]: isDisabled
          })}
          style={{
            minWidth: backgroundWidth || width,
            height: backgroundHeight || height,
            backgroundColor: (isHovering && !isDisabled ? hoverBackgroundColor : backgroundColor) || 'transparent',
            marginRight,
            marginLeft,
            marginTop,
            marginBottom,
            borderRadius,
            ...(containerStyle || style)
          }}
          onMouseEnter={(e: any) => {
            onMouseEnter?.(e)
            if (hoverColor || hoverBackgroundColor) {
              setIsHovering(true)
            }
          }}
          onMouseLeave={() => {
            onMouseLeave?.()
            if (isHovering) {
              setIsHovering(false)
            }
          }}
          onClick={e => onClick?.(e)}
          ref={iconRef}
        >
          {/* @ts-ignore*/}
          {IconWithHover ? <IconWithHover /> : IconComponent}
        </div>
      </Tooltip>
    )
  }
)

export default Icon
