import compact from 'lodash/compact'
import { Workflow } from 'src/common/types/common.types'
import { InputParams } from 'src/common/utils/types'
import { TRIGGER_STEP_PARAM_ID } from 'src/modules/wfe/wfe.constants'
import { getInputTemplate, getStepActionForUI, isCliIntegration } from './wfe.helper'
import { Parameter, Step } from './wfe.types'
import { CLI_COMMANDS, IGNORED_PARAMS_FOR_CLI } from './wfe.constants.aws'
import {
  datastoreFilterToCore,
  keyValueByTriggerToCore,
  keyValueByTriggerToUI,
  multiSelectToCore,
  multiSelectToUI,
  timePeriodCompactToCore,
  timePeriodCompactToUI
} from './wfe.convertorsByType'
import { dynamicOutputToBE, dynamicOutputToUI } from './wfe.convertorsDynamicOutput'

export const getCLIParametersFromCommand = (parameters: Array<Parameter>, template: InputParams[]) => {
  // if command = --describe-table some-table --region us-east-1
  // then for describe-table, will return "some-table"

  const command = parameters.find(param => CLI_COMMANDS.includes(param.id))?.value
  const commandParamsStrings = compact(command?.split('--'))
  const commandParameters = commandParamsStrings.map(param => {
    return {
      id: param.substring(0, param.indexOf(' ')),
      value: param.substring(param.indexOf(' ') + 1)
    }
  })

  // non-generic fields conversion. the generic approach here will be harder to react & maintain
  const getParamId = (paramId: string) => {
    if (paramId.includes('include-all-instances')) {
      return 'all-instances'
    }

    if (paramId.includes('all-regions')) {
      return 'all-regions'
    }

    return paramId
  }

  const getParamValue = (paramId: string, value: string) => {
    if (paramId.includes('all-instances')) {
      if (command?.includes('--no-include-all-instances')) {
        return 'no-include-all-instances'
      } else if (command?.includes('--include-all-instances')) {
        return 'include-all-instances'
      } else {
        return ''
      }
    }

    if (paramId.includes('all-regions')) {
      if (command?.includes('--no-all-regions')) {
        return 'no-all-regions'
      } else if (command?.includes('--all-regions')) {
        return 'all-regions'
      } else {
        return ''
      }
    }

    if (typeof value === 'string') {
      return value.trim()
    }

    return value
  }

  const templateParameters = template?.map(param => {
    const paramValue = parameters.find(p => p.id === param.id)?.value
    const commandValue = commandParameters.find(p => p.id === param.id)?.value

    return {
      id: getParamId(param.id),
      value: getParamValue(param.id, commandValue ?? paramValue ?? '')
    }
  })

  return templateParameters
}

export const getCliCommandFromParameters = (step: Step) => {
  const otherParams = step.parameters.filter(
    param => param.value && !IGNORED_PARAMS_FOR_CLI.includes(param.id) && !isCliIntegration(param)
  )

  const stepForUI = getStepActionForUI(step.subgroup, step.stepTemplateId)

  // --describe-table some-table --region us-east-1
  const command = (stepForUI?.description ?? '').replace('aws ', '').replace('gcloud ', '').replace('azure ', '')

  const commandParams =
    command +
    ' ' +
    otherParams
      .map(param => {
        return '--' + param.id + ' ' + param.value
      })
      .join(' ')
      .trim()

  // non-generic fields conversion. the generic approach here will be harder to react & maintain
  return commandParams.replace('--all-instances ', '--').replace('--all-regions ', '--').trim()
}

export const parametersToCore = ({ step, isSendingToBE }: { step: Step; isSendingToBE?: boolean }) => {
  const visibleParams = isSendingToBE
    ? step.parameters.filter(param => !getInputTemplate(step, param.id)?.isHiddenToBE)
    : step.parameters

  const coreParams = visibleParams.map(param => {
    const template = getInputTemplate(step, param.id)

    if (!template?.valueToCore) {
      return {
        id: param.id,
        value: param.value,
        valueToUI: param.valueToUI,
        type: template?.type
      }
    }

    return { id: param.id, value: template?.valueToCore(step), type: template?.type }
  })

  const convertedParams = isSendingToBE
    ? coreParams.map(param => {
        const template = getInputTemplate(step, param.id)

        if (CLI_COMMANDS.includes(param.id)) {
          return { ...param, value: getCLICommandToBE(param.value) }
        }

        if (param.id === TRIGGER_STEP_PARAM_ID) {
          //UI removes trigger.
          //when fetching workflows, BE combines it with workflow
          return { ...param, value: TRIGGER_STEP_PARAM_ID }
        }

        if (param.type === 'key-value-by-trigger') {
          return keyValueByTriggerToCore({ ...param, value: dynamicOutputToBE(param.value) })
        }

        if (param.type === 'filter') {
          return datastoreFilterToCore({ ...param, value: dynamicOutputToBE(param.value) })
        }

        if (param.type === 'multi-select' || param.type === 'connected-accounts-multi-select') {
          return multiSelectToCore({ ...param, value: dynamicOutputToBE(param.value) })
        }

        if (template?.type === 'time-period' && template?.variant === 'compact') {
          return {
            ...param,
            value: timePeriodCompactToCore(param.value)
          }
        }

        return { ...param, value: dynamicOutputToBE(param.value) }
      })
    : coreParams

  return convertedParams
}

export const parametersToUI = ({ step, isSendingToUI }: { step: Step; isSendingToUI?: boolean }) => {
  const masterParam = (step.parameters || []).find(param => {
    const template = getInputTemplate(step, param.id)

    return !!template?.parametersToUI
  })

  if (!masterParam) {
    return step.parameters.map(param => {
      const template = getInputTemplate(step, param.id)
      const type = template?.type

      if (type === 'key-value-by-trigger') {
        return keyValueByTriggerToUI({ ...param, value: dynamicOutputToUI(param.value) })
      }

      if (param.type === 'multi-select' || param.type === 'connected-accounts-multi-select') {
        return multiSelectToUI({ ...param, value: dynamicOutputToUI(param.value) })
      }

      if (type === 'time-period' && template?.variant === 'compact') {
        return timePeriodCompactToUI(param)
      }

      return { ...param, value: dynamicOutputToUI(param.value) }
    })
  }
  const masterTemplate = getInputTemplate(step, masterParam.id)

  const convertedParams = isSendingToUI
    ? step.parameters.map(param => {
        if (CLI_COMMANDS.includes(param.id)) {
          return { ...param, value: getCLICommandToUI(param.value) }
        }

        const uiValue = dynamicOutputToUI(param.value)
        const value = uiValue

        return { ...param, value }
      })
    : step.parameters

  return masterTemplate?.parametersToUI?.(convertedParams)
}

export const getCLICommandToBE = (command: string) => {
  return dynamicOutputToBE(command ?? '').trim()
}

export const getCLICommandToUI = (command: string) => {
  return dynamicOutputToUI(command ?? '')
}

export const workflowToBE = (workflow: Partial<Workflow>) => {
  return {
    ...workflow,
    graph: {
      ...workflow.graph,
      nodes: workflow.graph?.nodes.map(node => {
        const step = node.data?.step
        if (!step) {
          return node
        }

        return {
          ...node,
          data: { ...node.data, step: { ...step, parameters: parametersToCore({ step, isSendingToBE: true }) } }
        }
      })
    }
  }
}

export const workflowToUI = (workflow: Workflow) => {
  if (!workflow) {
    return undefined
  }

  return {
    ...workflow,
    graph: {
      ...workflow.graph,
      nodes: workflow.graph?.nodes.map(node => {
        const step = node.data?.step
        if (!step) {
          return node
        }

        return {
          ...node,
          data: { ...node.data, step: { ...step, parameters: parametersToUI({ step, isSendingToUI: true }) } }
        }
      })
    }
  }
}
