import { GridColDef } from '@mui/x-data-grid-pro'
import { stringToDisplay } from 'src/common/utils/str.util'

export const getColumns = (filteredData: any) => {
  if (!Array.isArray(filteredData)) {
    return []
  }

  const keys = filteredData.reduce((accKeys: any, row: any) => {
    Object.keys(row).forEach(key => accKeys.add(key))

    return accKeys
  }, new Set())

  return Array.from(keys).map(key => {
    return {
      flex: 1,
      headerName: stringToDisplay(key as string),
      field: key
    }
  }) as GridColDef[]
}
