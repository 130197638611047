import OrganizationDropdown from 'src/common/layouts/components/shared-components/OrganizationDropdown'
import UserDropdown from 'src/common/layouts/components/shared-components/UserDropdown'
import { Flex } from '../styled-components/flex'
import styles from './styles.module.css'

export const AppBar = () => {
  return (
    <Flex className={styles.appBar} sx={{ width: '100%', justifyContent: 'space-between' }}>
      <img width={50} src={'/images/logo.png'} alt='wiv' />
      <Flex>
        <OrganizationDropdown />
        <UserDropdown />
      </Flex>
    </Flex>
  )
}
