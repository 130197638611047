import { NodeSubType, NodeType } from 'src/common/constants/common.constants'
import { NodeData } from '../wfe.types'
import { BasicActionNodeStrategy } from './wfBasicActionNodeStrategy'
import { BasicFlowControlNodeStrategy } from './wfBasicFlowControlNodeStrategy'
import { BreakFlowControlNodeStrategy } from './wfBreakFlowControlNodeStrategy'
import { CollectFlowControlNodeStrategy } from './wfCollectFlowControlNodeStrategy'
import { ExitFlowControlNodeStrategy } from './wfExitFlowControlNodeStrategy'
import { IfFlowControlNodeStrategy } from './wfIfFlowControlNodeStrategy'
import { LoopFlowControlStrategy } from './wfLoopFlowControlNodeStrategy'
import { NodeStrategy } from './wfNodeStrategy'
import { ApproveFlowControlNodeStrategy } from './wfApproveFlowControlNodeStrategy'

// Define the strategy classes for each node type
const strategies: any = {
  [NodeType.Action]: {
    [NodeSubType.BasicAction]: new BasicActionNodeStrategy()
  },
  [NodeType.FlowControl]: {
    [NodeSubType.SubWorkflow]: new BasicFlowControlNodeStrategy(),
    [NodeSubType.Wait]: new BasicFlowControlNodeStrategy(),
    [NodeSubType.Trigger]: new BasicFlowControlNodeStrategy(),
    [NodeSubType.Loop]: new LoopFlowControlStrategy(),
    [NodeSubType.If]: new IfFlowControlNodeStrategy(),
    [NodeSubType.Break]: new BreakFlowControlNodeStrategy(),
    [NodeSubType.Collect]: new CollectFlowControlNodeStrategy(),
    [NodeSubType.Exit]: new ExitFlowControlNodeStrategy(),
    [NodeSubType.Approve]: new ApproveFlowControlNodeStrategy()
  }
}

// Define the strategy factory
export class NodeStrategyFactory {
  public static createStrategy(nodeData: NodeData): NodeStrategy {
    return strategies[nodeData.type][nodeData.subtype]
  }
}
