const Tooltip = () => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 0
        }
      }
    }
  }
}

export default Tooltip
