import { TriggerInputParameter } from 'src/modules/wfe/components/StepBar/TriggerType/types'
import { ValueAsObjectType } from '../../components/KeyValueList/types'
import { WorkflowRunStatuses } from '../../modules/monitoring/components/types'

export type WorkflowTemplate = {
  name: string
  description: string
  labels?: Array<string>
  path?: string
  graph: {
    nodes: any[]
    edges: any[]
  }
}

export const PUBLISHED = 'PUBLISHED'
export const UNPUBLISHED = 'UNPUBLISHED'

export type WorkflowState = typeof PUBLISHED | typeof UNPUBLISHED

export type NotificationEntityType = {
  type: string
  eventType: string
  recipients: Array<string>
}

export type Workflow = {
  lastRun?: string
  status?: keyof typeof WorkflowRunStatuses
  approvalContext?: ValueAsObjectType[]
  workflowId: string
  revisionId: string
  name: string
  description?: string
  createdBy: string
  creationTime: string
  lastUpdateBy: string
  lastUpdateTime: string
  state: WorkflowState
  labels: Array<string>
  path?: string
  graph: {
    nodes: any[]
    edges: any[]
  }
  inputParameters?: Array<TriggerInputParameter>
  notifications?: Array<NotificationEntityType>
}

// maximized-with-hinter is where a step contains the AI Hinter , so we maximized it too
export type Maximized = 'minimized' | 'maximized' | 'maximized-with-hinter'
