import { TriggerConfiguration } from '../wfe/components/StepBar/TriggerType/types'

export type CasesConfiguration = {
  id: string
  name: string
  workflowId: string
  workflowName: string
  integration?: string
  labels: string[]
  state: string
  type: Tabs
  inputParameters: {
    id: string
    value: string
    description?: string
  }[]

  lastRuns: { status: string; time: string }[]
  schedule: TriggerConfiguration
}

export enum Tabs {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}
