import { isArray } from 'chart.js/helpers'
import { capitalize } from 'lodash'
import { StepAction } from 'src/modules/wfe/wfe.step.action'
import { strings } from '../../common/constants/strings'
import { FlowControlType, NodeType, StepGroupType, StepSubGroupType } from '../../common/constants/common.constants'
import { convertAlphanumeric } from '../../common/utils/str.util'

const NEW_COMMENT_NODE_LEFT_SHIFT = 450
const NEW_COMMENT_NODE_RIGHT_SHIFT = 35
const NEW_COMMENT_NODE_SHIFT_Y = 10
export const FLOW_CONTROL_ICONS_FOLDER = 'icons/flowcontrols/'

export const i18n: any = strings.addNodeModal
export const i18nWorkflows: any = strings.workflows

export const actionTabs = {
  all: {
    id: 'all',
    title: i18n.allActions
  },
  popular: {
    id: 'popular',
    title: i18n.popularActions
  }
}

export const GroupTabs = {
  Cloud: {
    id: 'cloud',
    title: i18n.cloud
  },
  Notifications: {
    id: 'notifications',
    title: i18n.notifications
  },
  Ticketing: {
    id: 'ticketing',
    title: i18n.ticketing
  },
  VersionControl: {
    id: 'versionControl',
    title: i18n.versionControl
  },
  Utilities: {
    id: 'utilities',
    title: i18n.utilities
  }
}

export const withSegmentsGroups = [GroupTabs.Cloud.id, GroupTabs.Utilities.id]

export const flowControlsNodes = [
  {
    iconName: 'exit',
    title: i18nWorkflows.flowControlsNodes.exit,
    hoverBackgroundColor: 'var(--blueSkyLight)',
    type: FlowControlType.Exit
  },
  {
    iconName: 'if',
    title: i18nWorkflows.flowControlsNodes.if,
    hoverBackgroundColor: 'var(--yellowLight)',
    type: FlowControlType.If
  },
  {
    iconName: 'wait',
    title: i18nWorkflows.flowControlsNodes.wait,
    hoverBackgroundColor: 'var(--babyBlueLight)',
    type: FlowControlType.Wait
  },
  {
    iconName: 'loop',
    title: i18nWorkflows.flowControlsNodes.loop,
    hoverBackgroundColor: 'var(--purpleLight)',
    type: FlowControlType.Loop
  },
  {
    iconName: 'collect',
    title: i18nWorkflows.flowControlsNodes.collect,
    hoverBackgroundColor: 'var(--purpleLight)',
    type: FlowControlType.Collect,
    isLoopScope: true
  },
  {
    iconName: 'break',
    title: i18nWorkflows.flowControlsNodes.break,
    hoverBackgroundColor: 'var(--purpleLight)',
    type: FlowControlType.Break,
    isLoopScope: true
  },
  {
    iconName: 'flow',
    title: i18nWorkflows.flowControlsNodes.flow,
    hoverBackgroundColor: 'var(--violetLight)',
    type: FlowControlType.SubWorkflow
  },
  {
    iconName: 'approve',
    title: i18nWorkflows.flowControlsNodes.approve,
    hoverBackgroundColor: 'var(--greenLight)',
    type: FlowControlType.Approve
  },
  {
    iconName: 'comment',
    width: 232,
    title: i18nWorkflows.flowControlsNodes.comment,
    hoverBackgroundColor: 'var(--blueLight)',
    type: FlowControlType.Comment
  }
]

export const FlowActionsSubgroups = {
  [GroupTabs.Notifications.id]: [StepSubGroupType.Slack, StepSubGroupType.Teams, StepSubGroupType.Email],
  [GroupTabs.Cloud.id]: [StepSubGroupType.AWS, StepSubGroupType.Azure, StepSubGroupType.GCP],
  [GroupTabs.VersionControl.id]: [StepSubGroupType.GitHub],
  [GroupTabs.Ticketing.id]: [StepSubGroupType.Jira],
  [GroupTabs.Utilities.id]: [
    StepSubGroupType.Workflow,
    StepSubGroupType.Array,
    StepSubGroupType.Api,
    StepSubGroupType.Format,
    StepSubGroupType.Network,
    StepSubGroupType.Math,
    StepSubGroupType.Variable,
    StepSubGroupType.Code,
    StepSubGroupType.Datastore,
    StepSubGroupType.Recommendation,
    StepSubGroupType.AnodotCost
  ]
}

export const addCommentNode = ({ nodes, quickMenuState, addNodes, setFocusedNode }: any) => {
  const placeholderNode = nodes.find((node: any) => node.id === quickMenuState.invoker.id)
  const creationTime = +new Date()
  const currentNode = nodes.find((n: any) => n.id === quickMenuState.invoker.id)
  const x = nodes.find(
    (node: any) =>
      node.type !== NodeType.COMMENT &&
      node.position.x < currentNode.position.x &&
      node.position.y === currentNode.position.y
  )
    ? currentNode.position.x + NEW_COMMENT_NODE_RIGHT_SHIFT
    : currentNode.position.x - NEW_COMMENT_NODE_LEFT_SHIFT

  const newNode = {
    creationTime,
    id: `COMMENT_${creationTime}`,
    type: NodeType.COMMENT,
    data: { comment: '' },
    position: {
      x,
      y: placeholderNode.position.y - NEW_COMMENT_NODE_SHIFT_Y
    }
  }

  addNodes([newNode])
  setFocusedNode({ type: NodeType.Invalid })
}

export const addActionNodeToGraph = ({ actionNode, handlePasteNewNodeEvent, invokerId, setQuickMenu }: any) => {
  const action = new StepAction(
    actionNode.group as StepGroupType,
    actionNode.subgroup as StepSubGroupType,
    actionNode.stepTemplateId,
    actionNode.title || '',
    actionNode.description,
    actionNode.type,
    actionNode.name,
    actionNode.parameters,
    actionNode.id
  )
  const nodeData = action.pasteStepNode()

  handlePasteNewNodeEvent(nodeData, invokerId)
  setQuickMenu({ visible: false })
}

export const getFilteredActions = (actions: any[], searchTerm: string) => {
  return actions.filter((action: any) => {
    const description = convertAlphanumeric(action.description)
    const title = convertAlphanumeric(action.title)
    const subgroup = convertAlphanumeric(action.subgroup)

    return description.includes(searchTerm) || title.includes(searchTerm) || subgroup.includes(searchTerm)
  })
}

export function groupBySegment(items: any = []) {
  const segmentGroups: any = {}
  let hasSegments = false

  items.forEach((item: any) => {
    if (item.segment) {
      hasSegments = true
      const segment = item.segment
      if (!segmentGroups[segment]) {
        segmentGroups[segment] = []
      }
      segmentGroups[segment].push(item)
    }
  })

  return hasSegments ? segmentGroups : items
}

export const getCollapsibleItems = ({ activeGroupTabId, stepsStore, selectedItem }: any) => {
  return FlowActionsSubgroups[activeGroupTabId].map(actionType => {
    const segments = groupBySegment(stepsStore.stepsGroups[actionType])
    const subItems = typeof segments === 'object' && !isArray(segments) ? Object.entries(segments) : undefined

    return {
      group: activeGroupTabId,
      isSelected: selectedItem?.id === actionType,
      title: (strings as any).integrations[actionType.toLowerCase()]?.name || capitalize(actionType),
      id: actionType,
      actions: segments,
      subItems: subItems?.map(([segment, actions]: any) => ({
        group: activeGroupTabId,
        title: segment,
        count: actions?.length,
        isSelected: selectedItem && selectedItem?.id === segment,
        subGroup: actionType,
        id: segment
      }))
    }
  })
}

export const onArrowDown =
  ({ searchTerm, selectedByKeyboardActionIndex, setSelectedByKeyboardActionIndex, filteredActions }: any) =>
  () => {
    if (!searchTerm) {
      if (selectedByKeyboardActionIndex !== -1) {
        setSelectedByKeyboardActionIndex(-1)
      }

      return
    }

    const flatFilteredActions = filteredActions.flat()
    if (searchTerm && selectedByKeyboardActionIndex < flatFilteredActions.length - 1) {
      setSelectedByKeyboardActionIndex(selectedByKeyboardActionIndex + 1)
    } else {
      setSelectedByKeyboardActionIndex(0)
    }
  }

export const onArrowUp =
  ({ filteredActions, searchTerm, selectedByKeyboardActionIndex, setSelectedByKeyboardActionIndex }: any) =>
  () => {
    const flatFilteredActions = filteredActions.flat()

    if (!searchTerm) {
      return
    }

    if (selectedByKeyboardActionIndex > 0) {
      setSelectedByKeyboardActionIndex(selectedByKeyboardActionIndex - 1)
    } else {
      setSelectedByKeyboardActionIndex(flatFilteredActions.length - 1)
    }
  }

export const onPressEnter =
  ({ filteredActions, selectedByKeyboardActionIndex, handleAddFlowControlNode, handleAddActionNodeToGraph }: any) =>
  () => {
    const flatFilteredActions = filteredActions.flat()

    if (Object.values(FlowControlType).includes(flatFilteredActions[selectedByKeyboardActionIndex]?.type)) {
      handleAddFlowControlNode(flatFilteredActions[selectedByKeyboardActionIndex])
    } else {
      handleAddActionNodeToGraph(flatFilteredActions[selectedByKeyboardActionIndex])
    }
  }
