// ** MUI Imports
import { Theme } from '@mui/material/styles'

const Label = (theme: Theme) => {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'inline-block !important',
          position: 'relative !important',
          transform: 'none !important',
          fontSize: '14px !important',
          padding: '0px !important',
          marginLeft: '0px !important',
          '&:not(.Mui-disabled)': {
            color: `${theme.palette.grey[500]} !important`
          }
        }
      }
    }
  }
}

export default Label
