import capitalize from 'lodash/capitalize'
import { Tabs } from 'src/modules/casesConfiguration/types'
import { pluralize } from '../utils/str.util'

export const strings = {
  common: {
    stepError: 'Step Error',
    total: 'Total',
    stopped: 'Stopped',
    running: 'Running',
    action: 'Action',
    addFilter: 'Add Filter',
    run: 'Run',
    version: 'Version',
    paste: 'Paste',
    bold: 'Bold (Ctrl+B)',
    italic: 'Italic (Ctrl+I)',
    underline: 'Underline (Ctrl+U)',
    bulletedList: 'Bulleted list',
    numberedList: 'Numbered list',
    checklistItem: 'Checklist',
    alignLeft: 'Left align',
    alignCenter: 'Center align',
    alignRight: 'Right align',
    cut: 'Cut',
    copy: 'Copy',
    enabled: 'Enabled',
    disabled: 'Disabled',
    comment: 'Comment',
    regions: 'Regions',
    failed: 'Failed',
    prompt: 'Prompt',
    beta: 'BETA',
    region: 'Region',
    tryAgain: 'Try again',
    approved: 'Approved',
    rejected: 'Rejected',
    logs: 'Logs',
    output: 'Output',
    ohNo: 'Oh No!',
    runStep: 'Run Step',
    generate: 'Generate',
    edit: 'Edit',
    goto: 'Go to',
    finish: 'Finish',
    copyError: 'Copy error',
    state: 'State',
    details: 'Details',
    runs: 'Runs',
    type: 'Type',
    new: 'New',
    error: 'Error',
    errors: 'Errors',
    costImpact: 'Cost Impact',
    yr: '/yr',
    all: 'All',
    selected: 'Selected',
    automate: 'Automate',
    status: 'Status',
    updated: 'Updated',
    criteria: 'Criteria',
    exportCSV: 'Export CSV',
    last30Days: 'Last 30 days',
    suggestions: 'Suggestions',
    createdBy: 'Created By',
    createdAt: 'Creation Time',
    updatedAt: 'Last Updated',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    selectAll: 'Select All',
    stop: 'Stop',
    email: 'Email',
    board: 'Board',
    mockEmail: 'example@wiv.ai',
    create: 'Create',
    delete: 'Delete',
    deleteConfirm: (name: string) => `Are you sure you want to delete ${name}?`,
    itemAdded: (item: string) => `${item} added`,
    itemUpdated: (item: string) => `${item} updated`,
    itemDeleted: (item: string) => `${item} deleted`,
    save: 'Save',
    displayType: 'Display Type',
    workflow: 'Workflow',
    workflowInline: 'workflow',
    workflows: 'Workflows',
    integration: 'Integration',
    integrations: 'Integrations',
    accounts: 'Accounts',
    events: 'Events',
    event: 'Event',
    approvals: 'Approvals',
    cancel: 'Cancel',
    apply: 'Apply',
    configuration: 'Configuration',
    emails: 'Emails',
    step: 'Step',
    pending: 'Pending',
    noSearchResults: 'No search results',
    remove: 'Remove',
    clearAll: 'Clear all',
    opportunity: 'Case',
    opportunities: 'Cases',
    caseManagement: 'Case Management',
    createFolder: '+ New Folder',
    optionalParameters: 'Optional Parameters',
    results: 'Results',
    setupTesting: 'Setup Testing',
    createSubFolder: '+ New Sub-Folder',
    cancelAction: 'Cancel',
    empty: '-',
    noData: 'No data to show',
    na: 'N/A',
    open: 'Open',
    closed: 'Closed',
    errorNotified: '. Our support team has been notified.',
    nonLatin: 'Only English letters, numbers, underscores (_) and hyphens (-) are allowed.',
    tooLong: (item: string, chars: number) => `${item} is too long. (Max size ${chars} characters)`,
    loadMoreButton: 'Load More Items',
    dataStore: 'Data Store',
    service: 'Service',
    channel: 'Channel',
    member: 'Member',
    submit: 'Submit',
    team: 'Team',
    teams: 'Teams',
    teamMembers: 'Team Members',
    none: 'None',
    noneExist: 'None Exist',
    selectIntegration: 'Please select integration',
    selectTeam: 'Please select team',
    search: 'Search',
    searchPlaceholder: 'Search...',
    filters: 'Filters',
    statuses: 'Statuses',
    tags: 'Tags',
    copyToClipboard: 'Copy To Clipboard',
    copied: 'Copied To Clipboard',
    updateTime: 'Update Time',
    days: 'Days',
    weekdays: 'Weekdays',
    weekdaysWithDelay: 'Weekday with Delay',
    dates: {
      nDays: 'N-Days',
      nDaysTitle: 'Number of Days',
      oneHour: '1 Hour',
      threeHours: '3 Hours',
      twelveHours: '12 Hours',
      oneDay: '1 Day',
      threeDays: '3 Days',
      oneWeek: '1 Week',
      twoWeeks: '2 Weeks',
      oneMonth: '1 Month',
      threeMonths: '3 Months',
      customDateRange: 'Custom Date Range',
      last30days: 'Last 30 days',
      last7days: 'Last 7 Days',
      last14days: 'Last 14 Days',
      last28days: 'Last 28 Days',
      last24hours: 'Last 24 Hours',
      last3months: 'Last 3 Months',
      last6months: 'Last 6 Months',
      custom: 'Custom',
      daily: 'Daily',
      weekly: 'Weekly',
      startDate: 'Start Date',
      endDate: 'End Date',
      d: 'D',
      w: 'W',
      m: 'M',
      justNow: 'Just Now',
      minutesAgo: 'Minutes Ago'
    },
    include: 'Include',
    exclude: 'Exclude'
  },

  inputFields: {
    optionalTitle: '(optional)'
  },
  email: {
    subject: 'Subject',
    recipients: 'Recipients',
    recipientsPlaceholder: 'recipient1@wiv.ai , recipient2@wiv.ai , ...',
    cc: 'CC Recipients',
    bcc: 'BCC Recipients'
  },

  integrations: {
    aws: {
      name: 'AWS',
      roleArn: 'Role ARN',
      externalId: 'External ID',
      invalidArn: 'Invalid role ARN structure',
      columns: {
        connectedAccounts: 'Connected Linked Accounts',
        awsAccount: 'AWS Account',
        linkedAccount: 'Linked Account',
        linkedAccountName: 'Linked Account Name',
        linkedAccountId: 'Linked Account ID',
        status: 'Status',
        eventName: 'Event Name',
        eventType: 'Event Type',
        service: 'Service',
        operation: 'Operation'
      }
    },
    gcp: {
      name: 'GCP',
      projectId: 'Billing Project ID',
      billingDatasetName: 'Billing Dataset Name',
      billingTableName: 'Billing Table Name',
      serviceAccountKey: 'Service Account Key',
      fileJsonError: 'File must be in JSON format',
      fileError: 'Error reading file',
      missingFileError: 'Please upload a Service Account Key file'
    },
    github: {
      name: 'GitHub'
    },
    slack: {
      name: 'Slack'
    },
    teams: {
      name: 'Microsoft Teams',
      botName: 'Bot Name',
      messageType: 'Message Type',
      privateMessage: 'Private Message',
      channelMessage: 'Channel Message',
      fallbackUser: 'Fallback User (Optional)'
    },
    azure: {
      name: 'Azure',
      appId: 'App ID',
      tenantId: 'Tenant ID',
      clientSecret: 'Client Secret'
    },
    email: {
      name: 'Email'
    },
    settings: {
      pleaseGoto: 'Please go to',
      andAdd: (integration: string) => `and add ${integration} integrations`,
      sameName: 'An integration with this name already exists',
      columns: {
        name: 'Name',
        createdAt: 'Created At'
      }
    },
    anodotcost: {
      name: 'Anodot Cost'
    }
  },

  errors: {
    failedToFetch(item: string) {
      return `Failed to fetch ${item}`
    },
    failedToUpdate(item: string) {
      return `Failed to update ${item}`
    },
    sameNameExist(item: string) {
      return `${item} with the same name already exists`
    },
    failedToRun(item: string) {
      return `Failed to run ${item}`
    },
    errorChangingState: 'Error changing status',
    errorChangingParameters: 'Error updating parameters',
    loopContext: 'Loop Context',
    addComment: 'error adding comment'
  },

  inspectorActivityLog: {
    title: 'Activity',
    changed: 'changed the status from',
    run: 'Run',
    opportunityWas: 'Case was',
    opened: 'Case was Opened',
    closed: 'Case was Closed',
    addComment: 'Add a Comment'
  },

  dashboardActivityLog: {
    title: 'Monitor Workflows Activity'
  },

  workflowsTable: {
    steps: {
      stepName: 'Step Name'
    },
    createFolderDisabledTooltip: 'Folder creation requires an existing workflow in Root',
    configureNotifications: 'Configure Notifications',
    columns: {
      state: 'Status',
      stateTooltip: 'Enable to automate workflow on schedule or events.\nDisabled workflows can still run manually.',
      lastUpdated: 'Last Updated',
      workflowName: 'Workflow Name',
      createdBy: 'Created By',
      runs: 'Runs',
      schedule: 'Schedule',
      lastRun: 'Last Run',
      nextRun: 'Next Run',
      creationTime: 'Creation Time',
      triggerType: {
        title: 'Trigger Type',
        manual: 'Manual',
        scheduled: 'Scheduled',
        integrationEvent: 'Integration Event'
      }
    }
  },

  workflows: {
    exitEditor: 'Exit Workflow Editor',
    createWorkflow: 'Create Workflow',
    generateWorkflowMenu: {
      option1: {
        title: 'Copilot Ai',
        subtitle: 'Create your automated workflow process using a simple prompt'
      },
      option2: {
        title: 'Create from Scratch',
        subtitle: 'Create your customized workflow tailored to your specific needs and goals'
      }
    },
    notFound: (id: string) => 'Workflow with id "' + id + '" not found',
    step: {
      name: 'Step Name',
      deleteStepTitle: 'Step Deletion Confirmation',
      deleteStepContent: (step: string) => `The step ${step} is referenced in the following steps:`,
      deleteStepContentConfirm: 'Are you sure you want to delete it?',
      deleteStepAction: 'Delete Step',
      allSelectedWarning: 'You are about to run this step on'
    },
    errors: {
      identified: 'Workflow Errors Identified:',
      emptyParameters: 'The following steps have empty required parameters:',
      nonExistedStepReference: 'The following steps have reference to non-existent steps:'
    },
    messages: {
      updateVersionNameError: 'Error while updating version name',
      setVersionError: 'Error while setting version',
      setVersionSuccess: 'Successfully set version',
      updateVersionNameSuccess: 'Successfully updated version name',
      initiatedRun: 'Successfully initiated workflow run',
      triggerRunError: 'Error running workflow'
    },
    restoreVersion: 'Restore this version',
    currentVersion: 'Current Version',
    notSave: 'Workflow is not saved',
    fallbackChannel: 'Fallback Channel (optional)',
    fallbackChannelInfo: 'Fallback channel is same as Channel',
    inputParameters: 'Workflow Parameters',
    cloneWorkflow: 'Clone Workflow',
    flowControlsNodes: {
      upperTitle: 'Flow Control',
      trigger: 'trigger',
      exit: 'Exit',
      if: 'If',
      wait: 'Wait',
      loop: 'Loop',
      collect: 'Collect',
      break: 'Break',
      flow: 'Flow',
      subworkflow: 'Flow',
      approve: 'Approve',
      comment: 'Comment'
    },
    parameterStatus: {
      loading: 'Test results loading',
      outdated: 'Test results likely out of date',
      missing: 'Please fill all step parameters',
      error: 'Step execution failed',
      fresh: 'Test results are fresh'
    }
  },
  keyValueList: {
    title: 'Filter',
    addValueDisabledTooltip: 'These values were already added',
    singleInputDisabledTooltip: 'This value was already added',
    keyAddedTooltip: 'This key was already added',
    valuePlaceholder: 'Value',
    removeFilter: 'Remove filter',
    listTitle: 'Current Filters'
  },
  datastore: {
    name: 'Data Store',
    noneExist: 'None Exist',
    newDatastore: '+ New Data store',
    messages: {
      emptySchema: 'Datastore has empty schema. Please edit this datastore and add a schema',
      invalidRecords: 'Datastore contains items with keys not defined on schema:',
      hasMoreItems: 'This datastore has more items to retrieve. Click Load More Items button to see more results.',
      updateRecord: 'Record updated',
      addRecord: 'Record successfully added',
      failAddRecord: 'Error adding record',
      updateRecordError: 'Error updating record',
      deleteRecord: 'Record deleted successfully',
      deleteRecordError: 'Error delete record',
      clearDatastore: 'Datastore was successfully cleared',
      failClearDatastore: 'Error clearing datastore',
      updateDatastore: 'Datastore was successfully updated',
      failUpdateDatastore: 'Error updating datastore'
    }
  },
  dynamicInput: {
    customData: {
      customDataPlaceholder: 'Custom data',
      title: 'Custom data',
      listTitle: ''
    }
  },
  filterAndOr: {
    selectFilter: 'Select a filter',
    selectValues: 'Select values',
    typeValues: 'Type values'
  },
  queryBuilder: {
    granularity: 'Granularity',
    dateRange: 'Date Range',
    groupBy: 'Group By',
    showQuery: 'Show Query',
    hideQuery: 'Hide Query',
    thresholdType: 'Threshold Type',
    filterByService: 'Filter by Service',
    costType: 'Cost Type',
    compareType: 'Compare Type',
    compareDays: (day1: number, day2: number) => `The cost of ${day1} days ago & ${day2} days ago`,
    compareWeeks: (week1: number, week2: number) => `The cost of ${week1} weeks ago & ${week2} weeks ago`,
    compareWeekDelay: 'The cost of Weekday Delay',
    customFilters: 'Add Custom Filters',
    thresholdAmount: 'Threshold Amount',
    thresholdPercentage: 'Threshold Percentage',
    checkCosts: 'Check if the grouped costs exceed the threshold without any comparison.',
    compareCosts: 'Compare costs between specific days.',
    compareCostsWeekdays:
      'Compare costs for each day of the week with the corresponding day from a specified number of weeks ago.',
    compareCostsWeekdaysWithDelay:
      'Compare costs for each day of the week with the corresponding day from a specified number of weeks ago, with a configurable delay.'
  },
  manageNotificationsDialog: {
    successMessage: 'Notifications have been successfully updated',
    disabledTooltip: 'Please enter a valid email',
    title: 'Manage notifications',
    explanationText: 'Notifications will be sent on failed executions of'
  },
  home: {
    yr: 'yr',
    import: 'Import',
    suggestedTemplates: 'Suggested Workflow Templates',
    topRecommendations: 'Top Cases',
    costImpact: 'Cost Impact',
    recommendation: (count: number) => (count === 1 ? 'Case' : 'Cases'),
    workflowsRuns: {
      weekPrefix: 'W',
      runs: 'Runs',
      totalRuns: 'Total Runs',
      title: 'Workflow Runs Over Time'
    },
    workflowsStatus: {
      title: 'Workflows & Approval Status'
    }
  },
  accountSpendersCard: {
    title: 'Top Account Spenders (5)',
    subtitle: 'Last 30 days'
  },
  costSummaryCard: {
    title: 'Costs & Savings Summary',
    previousMonthCost: 'Previous Month Cost',
    MTDCost: 'MTD Cost',
    CostImpact: 'Cost Impact'
  },
  topServiceSpendersCard: {
    title: 'Top Service Spenders (5)',
    subtitle: 'Last 30 days'
  },
  recommendations: {
    effort: 'Effort',
    effortAll: 'All Efforts',
    effortLow: 'Low',
    effortMedium: 'Medium',
    effortHigh: 'High',
    status: 'Status',
    statusAll: 'All Status',
    OPEN: 'Open',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    EXCLUDED: 'Excluded',
    automate: 'Automate',
    editStatus: 'Edit Status',
    age: 'Case Age',
    errorUpdateStatus: 'Error updating case status',
    successUpdateStatus: 'Successfully updated case status'
  },
  saveByEffortCard: {
    title: 'Save by Effort'
  },
  saveByStatusCard: {
    title: 'Total Cost Impact by Status'
  },
  opportunityCard: {
    costImpact: 'Cost Impact',
    accountName: 'Account Name',
    yr: '/yr',
    removeFromList: 'Remove from list'
  },
  opportunitiesByTypeCard: {
    title: 'Cases by Type',
    costImpact: 'Cost Impact',
    quantity: 'Quantity',
    other: 'Other'
  },

  opportunities: {
    title: 'Case Management',
    automate: 'Automate',
    noneToAutomate: 'No cases to Automate',
    noneToSearch: 'No cases found in search',
    exportCSVTitle: 'Cases',
    favoriteAutomation: 'Favorite Automation',
    addFavorite: 'Add Favorite',
    updateFavorite: 'Update Favorite',
    runWorkflow: 'Run Workflow',
    newFavorite: 'New Favorite',
    selectWorkflow: 'Select Workflow',
    reviewOpportunities: 'Review Cases',
    reviewOpportunitiesToAutomate: (number: number) => `Review ${number} ${pluralize('Case', number)} to Automate`,
    reviewSaveWorkflow: 'Review & Save Workflow',
    reviewRunWorkflow: 'Review & Run Workflow',
    monitorRun: 'Monitor Run',
    noOpenOpportunities: 'No Open Cases',
    noClosedOpportunities: 'No Closed Cases',
    maxCharactersWarning: 'Maximum characters exceeded (30)',
    noSpecialCharactersWarning: 'No special characters allowed',
    alreadyExistAutomationWarning: 'Automation with such name already exist',
    favoriteAutomationName: 'Favorite Automation Name',
    nextStep: 'Next Step',
    previous: 'Previous',
    save: 'Save',
    searchWorkflow: 'Search workflow...',
    selectWorkflowToRun: 'Select Workflow to Run',
    reviewWorkflowTitle: 'Review Workflow',
    lastRun: 'Last Run',
    createdBy: 'Created By',
    lastUpdatedTime: 'Last Update Time',
    state: 'State',
    triggerType: 'Trigger Type',
    workflowName: 'Workflow Name',
    addFavoriteAutomationError: 'Error while adding favorite automation',
    updateFavoriteAutomationError: 'Error while updating favorite automation',
    deleteFavoriteAutomationError: 'Error while deleting favorite automation',
    addFavoriteAutomationSuccess: 'New automation was successfully added',
    updateFavoriteAutomationSuccess: 'Automation was successfully updated',
    deleteFavoriteAutomationSuccess: 'Automation was successfully deleted',
    exit: 'Exit',
    cancel: 'Cancel',
    closeWizardDescription: 'You will lose any changes you’ve made on this wizard',
    closeWizardTitle: 'Are you sure you want to exit the wizard?',
    workflowParameters: 'Workflow Parameters',
    confirmRemoveOpportunityFromListTitle: 'Are you sure you want to remove case from list',
    confirmRemoveOpportunityFromListDescription: 'The case will not be processed on the automation run.'
  },

  boards: {
    newBoard: '+ New Board',
    addBoard: 'Create Board',
    updateBoard: 'Board Updated',
    addedBoard: 'Board Created',
    deletedBoard: 'Board Deleted',
    deleteConfirmTitle: 'Confirm Delete Board',
    boardType: 'Board Type',
    boardTypes: {
      recommendation: 'Recommendation',
      alert: 'Alert',
      governance: 'Governance'
    },

    cases: {
      title: 'Case Management',
      columns: {
        name: 'Board Name',
        boardType: 'Board Type',
        createdBy: 'Created By',
        lastUpdatedTime: 'Last Updated',
        costImpact: 'Cost Impact'
      }
    }
  },

  casesConfiguration: {
    title: 'Case Configuration',
    tabDisplay: (tab: string) => (tab === Tabs.SYSTEM ? 'System Cases' : 'My Cases'),
    scheduleType: (type: any) => capitalize(type),
    editState: 'Edit Status',
    updateParams: 'Parameters updated',
    updateSchedule: 'Schedule updated',
    runTitle: 'Execute Cases',
    runContent: 'Execute These Cases?',
    runButton: 'Execute',
    runMessage: 'Executing Cases',
    allIntegrations: 'All Integrations',
    schedule: {
      title: 'Edit Schedule -',
      titleMulti: (length: number) => length + ' cases',
      titleSingle: (name: string) => `'${name.trim()}'`
    },
    columns: {
      case: 'Case',
      createdBy: 'Created By Workflow',
      integration: 'Integration',
      scheduled: 'Scheduled',
      inputParameters: 'Parameters',
      failedRun: (date: string) => `Failed Run on ${date}`
    }
  },

  approvals: {
    approvalMessage: 'Approval Message',
    resendApprovalMessage: 'Resend approval message if unanswered for more than',
    newApprovalRequest: 'New Approval Request',
    fallbackChannel: 'Fallback Channel',
    fallbackMethod: 'Fallback Method',
    from: 'From (optional)',
    fallbackRecipients: 'Fallback Recipients',
    approveLabel: '✅ Approve',
    rejectLabel: '❌ Reject'
  },
  horizontalLine: {
    statusTitles: {
      open: 'Open',
      in_progress: 'In Progress',
      excluded: 'Exclude',
      completed: 'Completed'
    }
  },
  opportunityDetails: {
    serviceGroup: 'Service Group',
    opportunityStatus: 'Case Status'
  },
  opportunityInspector: {
    'Account ID': 'Account ID',
    'Account Name': 'Account Name',
    Region: 'Region',
    'Instance ID': 'Instance ID',
    'Instance Name': 'Instance Name',
    'Stop Date': 'Stop Date',
    'Stop Duration': 'Stop Duration',
    Volumes: 'Volumes',
    generatedBy: 'Generated By',
    statusTitles: {
      open: 'Open',
      in_progress: 'In Progress',
      excluded: 'Exclude',
      completed: 'Completed'
    }
  },
  automationWorkflowRunning: {
    running: {
      title: 'Workflow Running'
    },
    failed: {
      title: 'Failed Run'
    },
    success: {
      title: 'Successful Run'
    }
  },
  workflowStatusTag: {
    running: 'Running',
    failed: 'Failed',
    success: 'Success'
  },
  runningWorkflowStatus: {
    runningMessage:
      'This may take a while. You don’t have to stay in the wizard.\n You can view workflow’s run status on Runs page',
    goToRunPage: 'Go to Run Page',
    workflowError: 'Workflow Error'
  },
  workflowParameters: {
    fillParameter: 'Value is required',
    pleaseFillParameters: 'Please fill all required parameters.',
    resultsOutOfDate: 'Results out of date',
    testStepAgainToRefreshResults: 'Test step again to refresh results',
    emptyResults: 'Empty Results'
  },
  filters: {
    selectStatus: 'Select Status'
  },
  aiGeneratorBar: {
    placeholder: 'Type any prompt to generate your workflow'
  },
  generateWorkflowPopup: {
    cards: {
      listS3: 'List all S3 idle buckets',
      identifyEBS: 'Identify all EBS unattached volumes',
      generateLastMonth: 'Generate last month cost email report',
      dailyThreshold: 'Create daily cost threshold alert'
    },
    title: 'What workflow would you like to generate?',
    subtitle: 'Generate your workflow using a simple prompt. Powered by generative ai',
    placeholder: 'Type any prompt to generate your workflow',
    cardsTitle: 'Suggested workflows to generate'
  },
  generatingWorkflowModal: {
    title: 'Your workflow is being generated...',
    subtitle: 'This may take a while'
  },
  failGenerateWorkflowModal: {
    errorMessage: 'Couldn’t generate the prompt you typed'
  },
  forms: {
    approvalRequest: 'Approval Request',
    rejectRequestDescription:
      'Our form supports partial approvals.\nHowever, if you select "Reject", all items in the list will be rejected.',
    approveInstanceToTerminate: 'Approve instances to terminate',
    rejectInstanceToTerminate: 'Reject all instances to terminate',
    approvedInstancesForTermination: 'Approved instances for termination',
    requestSubmitted: 'Request Submitted',
    rejectedInstancesForTermination: 'All instances rejected for termination',
    formWasSubmitted: 'Request Was Already Submitted',
    submitTime: 'Submit Time: ',
    submittedBy: 'Submitted By: ',
    noPermission: 'You do not have permission to view this form'
  },
  generatedWorkflowSummary: {
    workflowDetails: 'Workflow Details',
    generatedWorkflow: 'Generated Workflow'
  },
  commentNode: {
    placeholder: 'Add a comment...',
    dialogText: 'Are you sure you want to delete that comment? '
  },
  addNodeModal: {
    noActionFound: 'No actions match this search',
    flowControls: 'Flow Controls',
    search: 'Search',
    action: 'Action',
    popularActions: 'Popular Actions',
    allActions: 'All Actions',
    cloud: 'Cloud',
    notifications: 'Notifications',
    ticketing: 'Ticketing',
    versionControl: 'Version Control',
    utilities: 'Utilities'
  },
  wfeHeader: {
    runFailed: 'Run Failed',
    errorMessage: 'Workflow name is already taken',
    workflowRunning: 'Workflow Running',
    runSuccess: 'Run Success'
  },
  runs: {
    Event: 'Event',
    Workflow: 'Workflow',
    Status: 'Status',
    eventSource: 'Event Source',
    eventTime: 'Event Time',
    hasMoreItems: 'There are more items to retrieve. Click Load More Items button to see more results.'
  },
  datastores: {
    newDatastore: '+ New Data store',
    title: 'Datastores'
  },
  dashboards: {
    newDashboard: '+ New Dashboard',
    title: 'Dashboards'
  }
}
