export const AWS_CLI_COMMAND = 'AWS-001-P1'
export const GCP_CLI_COMMAND = 'GCP-001-P2'
export const AZURE_CLI_COMMAND = 'AZURE-001-P2'
export const CLI_COMMANDS = [AWS_CLI_COMMAND, GCP_CLI_COMMAND, AZURE_CLI_COMMAND]
export const AWS_ATHENA_QUERY = 'AWS-006-P3'
export const CLI_INTEGRATIONS = [
  'AWS-001-P2',
  'AWS-013-P1',
  'AWS-014-P1',
  'AWS-016-P1',
  'AWS-006-P1',
  'GCP-001-P1',
  'AZURE-001-P1'
]
export const SLACK_INTEGRATIONS = ['SLACK-001-P2', 'SLACK-002-P2', 'SLACK-003-P2']
export const TEAMS_INTEGRATIONS = ['TEAMS-001-P2', 'APPROVAL-001-P2']
export const SEND_EMAIL = 'EMAIL-001-P2'

export const HIDDEN_TIMEZONE = 'hidden-timezone'
export const AWS_ACCOUNT_ID = 'AWS-001-P4'
export const CONTINUE_ON_ERROR = ['AWS-001-P3', 'GCP-001-P3', 'AZURE-001-P3']

export const IGNORED_PARAMS_FOR_CLI = [
  ...CLI_INTEGRATIONS,
  ...CLI_COMMANDS,
  HIDDEN_TIMEZONE,
  AWS_ACCOUNT_ID,
  ...CONTINUE_ON_ERROR
]

export const FIVE_MINUTES = '300'
export const ONE_HOUR = '3600'
export const ONE_DAY = '86400'
export const ONE_WEEK = '604800'

export const NOTIFICATION_TYPE = 'APPROVAL-001-P1'
export const NOTIFICATION_PARAMETERS = 'APPROVAL-001-P2'
export const ENABLE_RETRIES = 'APPROVAL-001-P3'
export const RETRIES_QUANTITY = 'APPROVAL-001-P4'
export const RETRIES_QUANTITY_TYPE = 'APPROVAL-001-P5'
export const APPROVE_LABEL = 'APPROVAL-001-P7'
export const REJECT_LABEL = 'APPROVAL-001-P8'
export const MAXIMUM_RETRIES = 'APPROVAL-001-P6'

export const ATHENA_TABLE = 'wivdb.summary_view'
