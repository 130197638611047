import React, { useState } from 'react'
import { Menu, MenuItem, Radio, TextField, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import commonStyles from 'src/common/styles/index.module.css'
import { Version } from '../../../../../hooks/useWFVersions'
import { useWFId } from '../../../../../hooks/useWFId'
import { strings } from '../../../../../../../common/constants/strings'
import Icon from '../../../../../../../components/Icon'
import TruncateTitle from '../../../../../../../components/TruncateTitle'
import { getFormattedDate, isDateFormatValid } from '../../../util'
import { useUpdateWorkflowVersionAttributes } from '../../../../../hooks/useUpdateWorkflowVersionAttributes'
import styles from './style.module.css'

const MAX_TITLE_LENGTH = 50
const i18n = strings.workflows

type WFVersionProps = {
  currentVersion: Version | null
  setCurrentVersion: (version: Version) => void
  setActiveWorkflowVersion: (versionId: string) => void
  setMessage: (message: string) => void
  setVersions: any
  versions: Version[]
  workflow: any
}

const WorkflowVersions = ({
  currentVersion,
  setCurrentVersion,
  setActiveWorkflowVersion,
  setMessage,
  setVersions,
  versions,
  workflow
}: WFVersionProps) => {
  const wfid = useWFId()
  const [newName, setNewName] = useState<string | null>(null)
  const { mutate: updateWorkflowVersionAttributes } = useUpdateWorkflowVersionAttributes(wfid)
  const [versionToEdit, setVersionToEdit] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const theme = useTheme()

  const versionTitle = `${strings.common.version}: ${getFormattedDate(
    currentVersion?.name || currentVersion?.LastModified
  )}`

  const onSelectVersion = (version: Version) => {
    if (currentVersion?.VersionId !== version.VersionId) {
      setCurrentVersion(version)
      setAnchorEl(null)
      setNewName('')
      setVersionToEdit(null)
      if (!version.IsLatest) {
        setActiveWorkflowVersion(version.VersionId)
      } else {
        setActiveWorkflowVersion('')
      }
    }
  }

  const onUpdateVersionName = (version: Version) => {
    if (version.name !== newName && newName) {
      updateWorkflowVersionAttributes(
        {
          versionId: version.VersionId,
          attributes: { name: newName }
        },
        {
          onError: () => setMessage(i18n.messages.updateVersionNameError),
          onSuccess: () => {
            setMessage(i18n.messages.updateVersionNameSuccess)

            // Optimistic update
            setVersions((prev: Version[]): Version[] =>
              prev.map((item: Version) => (item.VersionId === version.VersionId ? { ...version, name: newName } : item))
            )
          }
        }
      )
      setVersionToEdit(null)
      setNewName('')
    }
  }

  return (
    <Tooltip title={versionTitle.length >= MAX_TITLE_LENGTH ? versionTitle : undefined} placement='top'>
      <>
        <div ref={anchorEl} onClick={e => setAnchorEl(e.currentTarget)} className={styles.workflowName}>
          <TruncateTitle className={styles.versionTitle} maxLength={MAX_TITLE_LENGTH} title={versionTitle} />
          <div className={anchorEl ? commonStyles.transformIcon : ''}>
            <Icon name='chevron-down' height={24} width={24} marginBottom={anchorEl ? -2 : 3} />
          </div>
        </div>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} className={styles.menu}>
          {versions.map(version => (
            <MenuItem
              key={version.VersionId}
              value={version.VersionId}
              sx={{
                backgroundColor: version.VersionId === currentVersion?.VersionId ? '#FFE9F1' : '',

                '&:hover': {
                  color: version.VersionId !== currentVersion?.VersionId ? '#EE2C79' : ''
                }
              }}
            >
              <>
                <Radio
                  color='primary'
                  sx={{
                    '& [data-testid="RadioButtonCheckedIcon"] path': {
                      color: theme.palette.primary.main
                    },
                    '& [data-testid="RadioButtonUncheckedIcon"] path': {
                      color: '#FF86B6'
                    }
                  }}
                  onClick={() => onSelectVersion(version)}
                  checked={version.VersionId === currentVersion?.VersionId}
                />
                <div className={styles.menuItem}>
                  {version.IsLatest && <span className={styles.currentVersion}>{i18n.currentVersion}</span>}
                  {versionToEdit !== version.VersionId && (
                    <div
                      className={styles.menuItemName}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setVersionToEdit(version.VersionId)
                        setNewName(version.name)
                      }}
                    >
                      {getFormattedDate(version.name || currentVersion?.LastModified)}
                    </div>
                  )}
                  {versionToEdit === version.VersionId && (
                    <TextField
                      value={newName}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          onUpdateVersionName(version)
                        }
                        event.stopPropagation()
                      }}
                      onChange={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setNewName(e.target.value)
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: 1
                        }
                      }}
                    />
                  )}
                  {!isDateFormatValid(version.name) && (
                    <div className={styles.text} style={{ opacity: 0.8 }}>
                      {getFormattedDate(version.LastModified)}
                    </div>
                  )}
                  <div className={styles.text}>{version.creator || workflow?.createdBy}</div>
                </div>
              </>
            </MenuItem>
          ))}
        </Menu>
      </>
    </Tooltip>
  )
}

export default WorkflowVersions
