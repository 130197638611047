import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { strings } from '../../../../../../common/constants/strings'
import { wivCaptureException } from '../../../../../../common/utils/sentry'

const i18n = strings.workflows

export const runTooltipContentRenderer =
  ({ getStepLink, isRunEnabled, workflow, missedRequiredFields, stepsWithInvalidReference }: any) =>
  () => {
    if (!isRunEnabled || !workflow || !isEmpty(missedRequiredFields) || !isEmpty(stepsWithInvalidReference)) {
      return (
        <>
          <span>
            <b>{i18n.errors.identified}</b>
          </span>
          <ol style={{ paddingLeft: '16px', marginTop: '8px' }}>
            {(!isRunEnabled || !workflow) && <li>{i18n.notSave}</li>}
            {!isEmpty(missedRequiredFields) && (
              <li>
                {i18n.errors.emptyParameters}
                {missedRequiredFields.map((step: any) => (
                  <div key={step.name}>{getStepLink(step)}</div>
                ))}
              </li>
            )}
            {!isEmpty(stepsWithInvalidReference) && (
              <li>
                {i18n.errors.nonExistedStepReference}
                {stepsWithInvalidReference.map((step: any) => (
                  <div key={step.name}>
                    {getStepLink(step)} to {step.invalidReferences.join(', ')}
                  </div>
                ))}
              </li>
            )}
          </ol>
        </>
      )
    }

    return ''
  }

export const onRunBtnClick =
  ({ missedRequiredFields, stepsWithInvalidReference, setIsConfirmRunOpen, runClick }: any) =>
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isEmpty(missedRequiredFields) || !isEmpty(stepsWithInvalidReference)) {
      return setIsConfirmRunOpen(true)
    } else {
      e.stopPropagation()

      return runClick()
    }
  }

export const stopClick =
  ({ workflowExecution, setIsWorkflowRunning, setToastMessage, setIsCallingRun, stop }: any) =>
  async () => {
    try {
      const { id: workflowId, triggerEventId } = workflowExecution
      await stop(workflowId, triggerEventId)
      setIsWorkflowRunning(false)
      setToastMessage('Workflow Stop Initiated Successfully')
    } catch (er) {
      setToastMessage('Error: Workflow Stop Execution Failed')
      setIsCallingRun(false)
    }
  }

export const runClick =
  ({
    setIsSaveEnabled,
    run,
    setIsWorkflowRunning,
    setIsWorkflowExecutionOpened,
    setToastMessage,
    setIsCallingRun
  }: any) =>
  async () => {
    try {
      setIsSaveEnabled(false)
      if (typeof run === 'function') {
        setIsWorkflowRunning(true)
        setIsWorkflowExecutionOpened(true)
        await run()
      }
    } catch (er) {
      setToastMessage('Error: Workflow Run Execution Failed')
      setIsCallingRun(false)
    }
  }

export const saveClick =
  ({ isSaving, setIsRunEnabled, save, setToastMessage, setIsSaveEnabled, setIsSaving }: any) =>
  async () => {
    if (isSaving) {
      return
    }

    try {
      setIsRunEnabled(false)
      if (typeof save === 'function') {
        const error = await save()
        if (error) {
          wivCaptureException({ error })
          setToastMessage(error + strings.common.errorNotified)
        }
      }
      setIsRunEnabled(true)
      setIsSaveEnabled(false)
    } catch (er) {
      setIsRunEnabled(true)
      setIsSaveEnabled(false)
      setIsSaving(false)
      if (er instanceof Error) {
        wivCaptureException(er)
        setToastMessage(er.message ?? 'Error: saving workflow' + strings.common.errorNotified)
      }
    }
  }
