// ** Type import
import { useUserPermissions } from 'src/common/auth/auth.hooks'
import { navigationItems } from 'src/common/constants/routes.constants'
import { useFeatureFlag } from 'src/common/hooks/useFeatureFlag'

export const useNavigationLinks = () => {
  const permissions = useUserPermissions()

  const casesconfigurations = useFeatureFlag('casesconfigurations')

  const flags: Record<string, boolean | undefined> = {
    casesconfigurations
  }

  const openNavigationItems = navigationItems.filter(({ flag, flagHide }) => {
    if (flagHide && flags[flagHide as keyof object]) {
      return false
    }

    if (!flag) {
      return true
    }

    return flags[flag as keyof object]
  })

  return openNavigationItems.filter(({ permission }) => !permission || permissions.includes(permission))
}
