// ** MUI Import
// import { useTheme } from '@mui/material/styles'
import { CircularProgress, useTheme } from '@mui/material'
import Image from 'next/image'
import { Flex } from '../styled-components/flex'

export const Spinner = ({
  size,
  variant = 'primary',
  style,
  width = 100,
  height = 100,
  className
}: {
  size?: 'small' | 'large'
  variant?: string
  style?: any
  width?: number
  height?: number
  className?: string
}) => {
  const theme = useTheme()

  if (size === 'small') {
    return (
      <CircularProgress
        className={className}
        style={{
          width: '20px',
          height: '20px',
          color: variant === 'primary' ? theme.palette.primary.main : 'white',
          ...style
        }}
      />
    )
  }

  return (
    <Flex
      className={className}
      sx={{
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Image priority src='/images/wiv-loader.gif' alt={'loading'} width={width} height={height} />
    </Flex>
  )
}
