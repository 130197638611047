import { useCallback, useEffect } from 'react'

type OnPressCallbacksAndKeys = {
  [key: string]: () => void
}

export const useOnKeyDown = (
  onPressCallbacksAndKeys: OnPressCallbacksAndKeys,
  isPressAvailable = true,
  additionalKey?: string,
  isPreventDefault = true
) => {
  const handleKeyDown = useCallback(
    (e: any) => {
      // Check if the additional key (Meta or Ctrl) is pressed
      const isCombinationKey = additionalKey === 'Meta' ? e.metaKey : e.ctrlKey

      if ((!additionalKey || isCombinationKey) && onPressCallbacksAndKeys[e.key]) {
        if (isPreventDefault) {
          e.preventDefault()
        }
        onPressCallbacksAndKeys[e.key]()
      }
    },
    [onPressCallbacksAndKeys, additionalKey]
  )

  useEffect(() => {
    if (isPressAvailable) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, isPressAvailable])
}
