import { SnackbarOrigin } from '@mui/material'
import { ITEMS_PER_PAGE_MEDIUM } from './pagination.constants'

export const MAX_ROW_PAGINATION = 20
export const SNACKBAR_PROPS = {
  anchorOrigin: { vertical: 'top', horizontal: 'center' } as SnackbarOrigin,
  autoHideDuration: 4000
}

export const initialPaginationModel = {
  page: 0,
  pageSize: MAX_ROW_PAGINATION
}

export const TABLE_HEIGHT_WRAP = 'calc(100% - 100px)'

export const END_ADORNMENT_STYLE = {
  '& .MuiAutocomplete-endAdornment': {
    top: 20,
    right: '0 !important'
  },
  '& .MuiIconButton-root': {
    padding: '9px'
  }
}

export const TABLE_PROPS = ({
  disableRowSelectionOnClick,
  sx
}: {
  disableRowSelectionOnClick?: boolean
  sx?: object
  rowHeight?: number
}) => {
  return {
    pagination: true,
    pageSize: MAX_ROW_PAGINATION,
    initialState: {
      pagination: {
        paginationModel: initialPaginationModel
      }
    },
    pageSizeOptions: ITEMS_PER_PAGE_MEDIUM,
    disableRowSelectionOnClick,
    className: disableRowSelectionOnClick ? 'disableRowSelectionOnClick' : '',
    rowHeight: 60,
    sx
  }
}

// please change both these lines together
export const RESPONSIVE_FONT_SIZE = 'clamp(10px, 4px + 0.5vw, 12px) !important'
export const responsiveCharWidth = (width: number) => {
  const fontSize = Math.max(10, Math.min(4 + 0.5 * width, 12))

  return fontSize * 0.6
}

export const TOP_BAR_HEIGHT = 135
export const TOP_BAR_HEIGHT_EDIT = 285
export const APP_BAR_HEIGHT = 50

export const PAGE_PADDING = '24px'
