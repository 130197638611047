import { NodeSubType, NodeType } from 'src/common/constants/common.constants'
import { EdgeType, IfResultType } from '../wfe.constants'
import { createEdge, createNewNodePlaceholder, createNewNodeStep, generateUniqueNodeId } from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { ScopedFlowControlNodeStrategy } from './wfScopedFlowControlNodeStrategy'

export class IfFlowControlNodeStrategy extends ScopedFlowControlNodeStrategy {
  addNode(wfModel: WorkflowModel, targetPlaceholderId: string, newNodeData: any): boolean {
    const graph = wfModel.getGraph()

    // Find placeholder node neighbor so we could:
    // 1. Remove Edge from placeholder to next edge
    // 2. Add Edge from target node new placeholder to the neighbor node
    let targetNodeChildId = undefined
    const placeholderNodeNeighbors = graph.outNeighbors(targetPlaceholderId)

    if (placeholderNodeNeighbors.length === 1) {
      targetNodeChildId = placeholderNodeNeighbors[0]

      // Remove Edge from placeholder to next edge
      graph.dropEdge(targetPlaceholderId, targetNodeChildId)
    }

    const newIfNodeId = generateUniqueNodeId(NodeSubType.If)
    const trueNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const falseNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const closingNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const newStepNode = createNewNodeStep(newIfNodeId, newNodeData, newNodeData.type)

    const trueNode = createNewNodePlaceholder(trueNodeId, newNodeData, { ifResultType: IfResultType.True }, true)
    const falseNode = createNewNodePlaceholder(falseNodeId, newNodeData, { ifResultType: IfResultType.False }, true)
    const closingNode = createNewNodePlaceholder(
      closingNodeId,
      newNodeData,
      {
        ifResultType: IfResultType.Close,
        scopeParentNodeId: newIfNodeId
      },
      true
    )

    let newEdges = [
      createEdge(EdgeType.Workflow, targetPlaceholderId, newStepNode.id),
      createEdge(EdgeType.IfResult, newIfNodeId, falseNode.id, {
        ifResultType: IfResultType.False
      }),
      createEdge(EdgeType.IfResult, newIfNodeId, trueNode.id, {
        ifResultType: IfResultType.True
      }),
      createEdge(EdgeType.Workflow, trueNode.id, closingNode.id),
      createEdge(EdgeType.Workflow, falseNode.id, closingNode.id)
    ]

    if (targetNodeChildId) {
      newEdges = newEdges.concat([createEdge(EdgeType.Workflow, closingNode.id, targetNodeChildId)])
    }

    wfModel.addNodesToGraph([newStepNode, falseNode, trueNode, closingNode]) // 5
    wfModel.addEdgesToGraph(newEdges) // 6

    return true
  }

  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    return super.removeNode(wfModel, nodeId)
  }
}
