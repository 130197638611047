import { getCliCommandFromParameters, getCLIParametersFromCommand } from 'src/modules/wfe/wfe.convertors'
import { getStepParametersInput } from 'src/modules/wfe/wfe.helper'
import { Parameter } from 'src/modules/wfe/wfe.types'

export const mapValueToCoreParameter = (parameter: any): Parameter => {
  parameter.valueToCore = getCliCommandFromParameters
  delete parameter.valueToCoreFlag

  return parameter
}

export const mapParamsToUi = (parameter: any): any => {
  if (!parameter.parametersToUI) {
    parameter.parametersToUI = (parameters: Parameter[]) => {
      return getCLIParametersFromCommand(parameters, getStepParametersInput(parameter.parametersToUIBase))
    }
  }

  return parameter
}
