const ToggleButtonGroup = () => {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: '24px',
          '& *': {
            borderRadius: '2px !important'
          }
        }
      }
    }
  }
}

export default ToggleButtonGroup
