import { Theme } from '@mui/material/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const List = (theme: Theme) => {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none !important'
        }
      }
    }
  }
}

export default List
