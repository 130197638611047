const BreadCrumbs = () => {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          padding: '0px !important',
          color: `var(--neutralBrandBlue80)`,
          '& a': {
            fontSize: '14px',
            fontWeight: '400'
          },
          '& p': {
            fontSize: '16px',
            color: `var(--primaryBlueBrand)`,
            fontWeight: '400'
          }
        }
      }
    }
  }
}

export default BreadCrumbs
