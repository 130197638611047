const isLocal =
  process.env.IS_LOCALHOST === 'true' || (typeof window !== 'undefined' && window.location?.hostname === 'localhost')

export const SENTRY_CONSTANTS = {
  dsn: isLocal ? '' : process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !isLocal,
  enableTracing: !isLocal,
  environment: process.env.NEXT_PUBLIC_APP_ENV,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1
}
