// authUtil.ts
export class AuthUtil {
  private static instance: AuthUtil
  private authToken = ''

  public static getInstance(): AuthUtil {
    if (!AuthUtil.instance) {
      AuthUtil.instance = new AuthUtil()
    }

    return AuthUtil.instance
  }

  public setAuthToken(token: string): void {
    this.authToken = token
  }

  public getAuthToken(): string {
    return this.authToken
  }
}
