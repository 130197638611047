const Link = () => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    }
  }
}

export default Link
