import React from 'react'
import cx from 'classnames'
import Icon, { IconProps } from '../Icon'
import styles from './index.module.css'

type ButtonVariants = 'primary' | 'secondary' | 'tertiary'

export type ButtonProps = {
  iconProps?: IconProps
  isActive?: boolean
  isDisabled?: boolean
  onClick?: (e?: any) => void
  title?: string
  variant?: ButtonVariants
  minWidth?: string | number
  children?: Element | any
}

const Button = ({
  iconProps,
  isActive,
  isDisabled,
  onClick,
  title,
  variant = 'primary',
  minWidth,
  children = null
}: ButtonProps) => {
  return (
    <button
      className={cx(styles.button, styles[variant], {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled
      })}
      style={{ minWidth }}
      onClick={!isDisabled ? onClick : undefined}
    >
      {iconProps && <Icon {...{ height: 16, width: 16, ...iconProps }} />}
      {title}
      {children}
    </button>
  )
}

export default Button
