import { isNumber } from 'src/common/utils/number.util'
import { ConditionModel, Rule } from '../IfStepParameters.utils'

export const transformKeyToOutput = (key: string) => 'record_data.' + key
export const transformKeyToInput = (key = '') => String(key).split('record_data.')?.at(-1) ?? ''

const extractRuleFromConditionObject = (conditionObject: any, id: number, index: number) => {
  const operator = conditionObject.operator ?? ''
  const value = conditionObject.value?.toString() ?? ''
  const keyValue = transformKeyToInput(conditionObject.key)

  return { id: id + index, keyValue, operator, value }
}

export const transformRuleToInputFormat = (rulesArray: any[]) => {
  return rulesArray.map((rule, index) => {
    const id = index + 1
    let newRule: any

    if (Object.prototype.hasOwnProperty.call(rule, 'and')) {
      newRule = {
        id,
        conditions: rule.and.map((c: any, index: number) => extractRuleFromConditionObject(c, id, index))
      }
    } else {
      newRule = { id, conditions: [extractRuleFromConditionObject(rule, id, 0)] }
    }

    return newRule
  })
}

const transformConditionToOutput = (condition: ConditionModel) => {
  const value = !isNumber(condition.value) ? condition.value : Number(condition.value)

  return {
    key: transformKeyToOutput(condition.keyValue),
    value,
    operator: condition.operator
  }
}
const transformRuleToOutput = (rule: Rule) => {
  const transformedConditions = rule.conditions.map(transformConditionToOutput)
  if (transformedConditions.length === 1) {
    return transformedConditions[0]
  } else {
    return {
      and: transformedConditions
    }
  }
}
export const transformRulesToOutput = (rules: Rule[]) => {
  return rules.length === 1 ? transformRuleToOutput(rules[0]) : { or: rules.map(transformRuleToOutput) }
}

export const conditions = [
  'equals',
  'not_equals',
  'greater_than',
  'greater_than_equals',
  'less_than',
  'less_than_equals',
  'contains'
]
