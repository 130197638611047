// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Layout Imports
// !Do not remove this Layout import
import { useLogoutFunction, useUser } from '@propelauth/nextjs/client'
import posthog from 'posthog-js'
import { init as initSaola } from '@saola.ai/browser'

// ** Component Import
// ** Hook Import
import { SpeedInsights } from '@vercel/speed-insights/next'
import WFEHeader from 'src/modules/wfe/components/WFEHeader'
import { AuthUtil } from 'src/common/auth/auth.util'

import WfeSteps from 'src/modules/wfe/wfe.steps'
import { fetchSteps } from 'src/services/steps.api'
import useStore from 'src/modules/wfe/wfe.store'
import { wivCaptureException } from 'src/common/utils/sentry'
import { sentryInit } from '../../../sentry.client.config'

import styles from './styles.module.css'
import { NavBar } from './NavBar'
import { AppBar } from './AppBar'

declare global {
  interface Window {
    Intercom: any
  }
}

const UserLayout = ({ children }: { children: ReactNode }) => {
  // ** Hooks

  const { user, accessToken } = useUser()
  const { isWFEditorOpen, setStepsLoaded } = useStore()

  const authUtil = AuthUtil.getInstance()

  const logoutFn = useLogoutFunction()

  useEffect(() => {
    if (accessToken) {
      authUtil.setAuthToken(accessToken)

      // propel-auth needs this in a react component
      // so we cant use axios interceptors
      const originalFetch = window.fetch

      window.fetch = async (...args) => {
        const [resource, config] = args
        const response = await originalFetch(resource, config)

        // redirect 401 calls to logout
        if (response.status === 401) {
          window.Intercom('shutdown')
          logoutFn()
        }

        return response
      }

      // Load steps
      const stepsStore = WfeSteps.getInstance()
      const loadSteps = async () => {
        try {
          const steps = await fetchSteps()
          stepsStore.setSteps(steps)
          setStepsLoaded(true)
        } catch (error) {
          wivCaptureException(error)
        }
      }

      const steps = stepsStore.getSteps()
      if (Object.keys(steps).length === 0) {
        loadSteps()
      }

      const email = user?.email ?? ''
      const username = email.substring(0, email.indexOf('@'))
      const org = user?.orgIdToOrgMemberInfo?.[user?.activeOrgId ?? '']?.orgName?.toLowerCase() ?? 'NONE'

      const noReplayOrgs = (process.env.NEXT_PUBLIC_NO_REPLAY_TENANTS ?? '').split(',')
      const disable_session_recording = noReplayOrgs.includes(org)

      initSaola(process.env.NEXT_PUBLIC_SAOLA_ID ?? '')

      if (!window.location.href.includes('localhost')) {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
          api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
          person_profiles: 'identified_only',
          disable_session_recording: disable_session_recording || process.env.NEXT_PUBLIC_APP_ENV !== 'production'
        })
        posthog.identify(email)
      }

      window.Intercom('shutdown')
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        name: username,
        email,
        created_at: user?.createdAt
      })

      if (!window['IS_SENTRY_INIT' as keyof object]) {
        sentryInit({
          user,
          disable_session_recording
        })

        //i want it outside useEffects
        ;(window as any).IS_SENTRY_INIT = true
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, user])

  return (
    <div>
      {isWFEditorOpen ? (
        <WFEHeader />
      ) : (
        <>
          <AppBar />
          <NavBar />
        </>
      )}
      <div className={styles.page} style={isWFEditorOpen ? { padding: '0px' } : {}}>
        {authUtil.getAuthToken() && children}
      </div>
      <SpeedInsights />
    </div>
  )
}

export default UserLayout
