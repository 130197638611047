import { EdgeType } from '../wfe.constants'
import { createEdge } from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { NodeData } from '../wfe.types'
import { NodeStrategy } from './wfNodeStrategy'
import { findNodesWithAttribute } from './wfStrategies.helper'

export class ScopedFlowControlNodeStrategy implements NodeStrategy {
  addNode(wfModel: WorkflowModel, nodeId: string, nodeData: NodeData): boolean {
    console.log(wfModel, nodeId, nodeData)

    return false
  }

  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    // Taking advantage of graphology library dropNode action which removes a node and all of its edges,
    // 1. We will first want to take note of the parent node of the target node
    // 2. (If exists) the child node of the target node placeholder child.
    // 3. We will remove the target node and their child placeholder node
    // 4. We will connect the parent of the target node and the child of the deleted placeholder child if it exists

    // Retrieving parent and child
    const graph = wfModel.getGraph()
    const placeholderParent = graph.inNeighbors(nodeId)[0] // there can only be one

    const startNodeId = nodeId
    const targetAttribute = 'scopeParentNodeId'
    const targetValue = nodeId

    const { attributeNode, traversedNodes: nodesToDelete } = findNodesWithAttribute(
      graph,
      startNodeId,
      targetAttribute,
      targetValue
    ) // last element would be our attribute node;
    const nodeGrandchild = graph.outNeighbors(attributeNode)[0] // there can only be one

    for (const node of nodesToDelete) {
      graph.dropNode(node)
    }

    if (nodeGrandchild) {
      wfModel.addEdgesToGraph([createEdge(EdgeType.Workflow, placeholderParent, nodeGrandchild)])
    }

    return true
  }
}
