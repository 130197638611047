import { Theme } from '@mui/material/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Toggle = (theme: Theme) => {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          lineHeight: '1 !important',
          textTransform: 'none !important'
        }
      }
    }
  }
}

export default Toggle
