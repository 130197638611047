// ** Theme Config Imports
import { Theme } from '@mui/material/styles'
import themeConfig from 'src/common/configs/themeConfig'

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`
          }
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: theme.palette.customColors.neutralBrandBlue40,
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.customColors.neutralBrandBlue40}`
          }
        }
      ],
      styleOverrides: {
        root: {
          padding: '2px 8px !important',
          borderRadius: '20px !important',
          boxShadow: 'none !important',
          fontWeight: 'bold !important',
          marginRight: '8px !important',
          textTransform: 'none',
          minWidth: '70px !important',
          '&:hover': {
            filter: 'brightness(120%) !important'
          },
          '& .MuiCircularProgress-root': {
            marginLeft: '4px'
          },
          '&.Mui-disabled': {
            backgroundColor: `#ddd !important`,
            color: `#aaa !important`,
            border: `1px solid transparent !important`
          },
          '&.no-border': {
            border: '0px !important',
            svg: { marginRight: '4px' },
            fontWeight: '500 !important',
            textTransform: 'uppercase !important'
          }
        },
        contained: {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.primary.light} !important`,
          border: `1px solid ${theme.palette.primary.main} !important`
        },
        outlined: {
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: `${theme.palette.primary.main} !important`,
          border: `1px solid ${theme.palette.primary.main} !important`
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple
      }
    }
  }
}

export default Button
