export class UniqueIdProvider {
  private static _instance: UniqueIdProvider
  private _ids: Record<string, Set<number>>

  private constructor() {
    this._ids = {}
  }

  public static getInstance(): UniqueIdProvider {
    if (!UniqueIdProvider._instance) {
      UniqueIdProvider._instance = new UniqueIdProvider()
    }

    return UniqueIdProvider._instance
  }

  public updateGenerator(title = '') {
    if (title) {
      const id = title.match(/\d+/)?.[0]
      const prefix = title.replace(/\d+$/, '')

      if (!this._ids[prefix]) {
        this._ids[prefix] = new Set<number>()
        this._ids[prefix].add(0)
      }

      if (id) {
        this._ids[prefix].add(Number(id))
        const array = Array.from(this._ids[prefix]).sort()

        this._ids[prefix] = new Set([...array])
      }
    }
  }

  public generateId(prefix = ''): string {
    if (!this._ids[prefix]) {
      this._ids[prefix] = new Set<number>()
      this._ids[prefix].add(0)

      return prefix
    } else {
      const array = Array.from(this._ids[prefix])
      const maxIndex = Math.max(...array)
      const newIndex = maxIndex + 1
      this._ids[prefix].add(newIndex)

      return `${prefix}${newIndex}`
    }
  }
}
