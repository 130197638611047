import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { useQueryClient } from 'react-query'
import { createWorkflowTriggerEvent, fetchWorkflow, stopWorkflow, updateWorkflow } from 'src/services/workflows.api'
import { NodeType } from 'src/common/constants/common.constants'
import { createTrigger, deleteTrigger, updateTrigger } from 'src/services/triggers.api'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import useStore from '../wfe.store'
import { createWorkflowObject, getTriggerNode, getTriggerValue } from '../wfe.helper'
import { workflowToBE, workflowToUI } from '../wfe.convertors'
import { TriggerConfiguration, TriggerInputParameter, TriggerTypes } from '../components/StepBar/TriggerType/types'

const selector = (state: any) => ({
  reactFlowInstance: state.reactFlowInstance,
  workflow: state.workflow,
  inputParameters: state.inputParameters,
  nodes: state.nodes,
  setWorkflowDataForRun: state.setWorkflowDataForRun,
  addWorkflowExecution: state.addWorkflowExecution,
  stopWorkflowExecution: state.stopWorkflowExecution,
  setFocusedNode: state.setFocusedNode,
  setIsWorkflowExecutionOpened: state.setIsWorkflowExecutionOpened,
  triggerId: state.triggerId,
  triggerType: state.triggerType,
  setTriggerTypeAndId: state.setTriggerTypeAndId
})

export const useWFAction = () => {
  const [isSaving, setIsSaving] = useState(false)
  const queryClient = useQueryClient()
  const [isCallingRun, setIsCallingRun] = useState(false)
  const {
    reactFlowInstance,
    workflow,
    inputParameters,
    nodes,
    setWorkflowDataForRun,
    addWorkflowExecution,
    stopWorkflowExecution,
    setFocusedNode,
    setIsWorkflowExecutionOpened,
    triggerId,
    triggerType,
    setTriggerTypeAndId
  } = useStore(selector, shallow)

  const { toObject } =
    reactFlowInstance !== null
      ? reactFlowInstance
      : {
          toObject: () => {
            return {}
          }
        }

  const save = async (): Promise<string> => {
    setIsSaving(true)

    const nodesAndEdges = createWorkflowObject(toObject())

    const savedWorkflow = {
      workflowId: workflow.workflowId,
      graph: nodesAndEdges,
      name: workflow.name,
      executions: workflow.executions,
      description: workflow.description,
      labels: workflow.labels,
      input_parameters: inputParameters?.filter((p: TriggerInputParameter) => !!p.id) ?? []
    }

    let error = ''

    try {
      await crudTrigger(nodesAndEdges)
    } catch (er) {
      error = 'Error saving trigger'
    }

    try {
      await updateWorkflow(savedWorkflow.workflowId, workflowToBE(savedWorkflow))
      const data = await fetchWorkflow(workflow.workflowId)
      setWorkflowDataForRun(workflowToUI(data))

      queryClient.setQueryData([QUERY_KEYS.workflows], (previous: Array<any> | undefined) => {
        return (previous ?? []).map(wf => {
          if (wf.workflowId === savedWorkflow.workflowId) {
            return { ...wf, inputParameters }
          }

          return wf
        })
      })

      // After saving new workflow version, update all versions
      queryClient.invalidateQueries(['workflows/versions', savedWorkflow.workflowId])
    } catch (er) {
      setIsSaving(false)

      return error + ' Error saving workflow'
    }

    setIsSaving(false)

    return error
  }

  const crudTrigger = async (nodesAndEdges: any) => {
    const triggerNode = getTriggerNode(nodesAndEdges.nodes)
    const triggerValue = getTriggerValue(triggerNode)
    const newTriggerType = (triggerValue?.type as keyof typeof TriggerTypes) ?? TriggerTypes.MANUAL
    const { workflowId } = workflow

    const trigger = {
      name: triggerNode.step.name,
      workflowId: workflowId,
      type: newTriggerType,
      configuration: (triggerValue?.configuration as TriggerConfiguration) ?? {}
    }

    let id = triggerId

    if (triggerType !== newTriggerType && id) {
      await deleteTrigger(workflowId, triggerId)
      setTriggerTypeAndId(newTriggerType, null)
      id = null
    }

    if (!id) {
      const data = await createTrigger(workflowId, trigger)
      id = data?.trigger?.trigger_id
    } else {
      const data = await updateTrigger(trigger, workflowId, triggerId)
      id = data?.trigger?.trigger_id
    }

    setTriggerTypeAndId(newTriggerType, id)
  }

  const run = async (): Promise<void> => {
    setIsCallingRun(true)
    setFocusedNode({
      type: NodeType.Invalid
    })

    const payload = inputParameters?.length
      ? inputParameters.reduce((acc: any, param: TriggerInputParameter) => {
          return {
            ...acc,
            [param.id]: param.defaultValue === undefined || param.defaultValue === '' ? null : param.defaultValue
          }
        }, {})
      : {}

    const eventData = await createWorkflowTriggerEvent(workflow.workflowId, '1', payload)
    const { trigger_event_id } = eventData.data
    await addWorkflowExecution(workflow, trigger_event_id)
    setIsCallingRun(false)
  }

  const stop = async (workflowId: string, executionId: string): Promise<void> => {
    await stopWorkflow(workflowId, executionId)
    setIsCallingRun(false)
    setIsWorkflowExecutionOpened(false)
    stopWorkflowExecution()
  }

  return { isSaving, setIsSaving, save, isCallingRun, setIsCallingRun, run, stop, workflow, nodes }
}
