import { Theme } from '@mui/material/styles'

const Switch = (theme: Theme) => {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': { color: theme.palette.primary.light },

          '& .MuiSwitch-track': {
            minWidth: '100%',
            backgroundColor: theme.palette.secondary.main
          },

          '& .Mui-checked': {
            '.MuiSwitch-thumb': { color: theme.palette.primary.main },
            '& + .MuiSwitch-track': {
              backgroundColor: `${theme.palette.primary.main} !important`,
              opacity: '0.4 !important'
            }
          }
        }
      }
    }
  }
}

export default Switch
