export const removeKeysFromObj = (obj: any, keysToRemove: string[]) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  for (const key of Object.keys(obj)) {
    if (keysToRemove.includes(key)) {
      delete obj[key]
    } else {
      removeKeysFromObj(obj[key], keysToRemove)
    }
  }
}

export const exportObject = (object: object, fileName: string) => {
  const blob = new Blob([JSON.stringify(object)], {
    type: 'application/json'
  })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export type TreeNode = {
  label?: string
  stepTemplateId?: string
  type?: string
  children?: TreeNode[]
}

export const filterTree = (root: TreeNode | undefined, searchText: string): TreeNode | undefined => {
  if (!root) {
    return undefined
  }

  const isMatching =
    !root.label ||
    root.label?.toLowerCase().includes(searchText.toLowerCase()) ||
    root.stepTemplateId?.toLowerCase().includes(searchText.toLowerCase())

  const filteredChildren = root.children
    ? root.children.map(child => filterTree(child, searchText)).filter(Boolean)
    : []

  if (isMatching || filteredChildren.length > 0) {
    return { ...root, children: filteredChildren as TreeNode[] }
  }

  return undefined
}

export const countLeafs = (node: TreeNode): number => {
  if (!node.children?.length) {
    return 1 // Leaf node
  }

  let count = 0

  for (const child of node.children) {
    count += countLeafs(child)
  }

  return count
}

export const getObjectAtPath = (obj: any, path: any = '') => {
  const keys = path.split('/')

  let currentObj = obj

  for (const key of keys) {
    currentObj = currentObj[key]

    if (!currentObj) {
      return undefined
    }
  }

  return currentObj
}
