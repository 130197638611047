import { RequestOptions } from 'https'
import { snakeCase } from 'lodash'
import { wivCaptureException } from 'src/common/utils/sentry'
import { AuthUtil } from '../common/auth/auth.util'

export const authUtil = AuthUtil.getInstance()

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL

export const request = async (url: string, params?: RequestOptions & Record<string, unknown>) => {
  const response = await fetch(`${API_BASE_URL}/${url}`, {
    ...params,
    headers: {
      ...params?.headers,
      authorization: authUtil.getAuthToken()
    }
  })
  if (!response.ok) {
    const error = await response.json()
    wivCaptureException(`Error ${url} ${params?.method ?? ''} ${JSON.stringify(error)}`)
    throw new Error(`Error ${url} ${params?.method ?? ''}`)
  }

  return await response.json()
}

function transformKeys(obj: any, transformFn: (key: string) => string, ignoreKeys: string[] = []): any {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(item => transformKeys(item, transformFn, ignoreKeys))
  }

  const result: any = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const transformedKey = transformFn(key)
      if (ignoreKeys.includes(key)) {
        // Use transformed key but don't transform the nested structure.
        result[transformedKey] = obj[key]
      } else {
        result[transformedKey] = transformKeys(obj[key], transformFn, ignoreKeys)
      }
    }
  }

  return result
}

export const dataToBE = (obj: any, ignoreKeys: string[] = []) => {
  //backend_vars_are_snake_case
  return transformKeys(obj, snakeCase, ignoreKeys)
}
