import { NodeSubType, NodeType } from 'src/common/constants/common.constants'
import { EdgeType, LoopResultType } from '../wfe.constants'
import {
  createEdge,
  createNewNodeInvisible,
  createNewNodePlaceholder,
  createNewNodeStep,
  generateUniqueNodeId
} from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { ScopedFlowControlNodeStrategy } from './wfScopedFlowControlNodeStrategy'

// Define the strategy classes for each node type
export class LoopFlowControlStrategy extends ScopedFlowControlNodeStrategy {
  addNode(wfModel: WorkflowModel, targetPlaceholderId: string, newNodeData: any): boolean {
    const graph = wfModel.getGraph()

    // Find placeholder node neighbor so we could:
    // 1. Remove Edge from placeholder to next edge
    // 2. Add Edge from target node new placeholder to the neighbor node
    let targetNodeChildId = undefined
    const placeholderNodeNeighbors = graph.outNeighbors(targetPlaceholderId)

    targetNodeChildId = placeholderNodeNeighbors[0]
    if (targetNodeChildId) {
      // Remove Edge from placeholder to next edge
      graph.dropEdge(targetPlaceholderId, targetNodeChildId)
    }

    const newLoopNodeId = generateUniqueNodeId(NodeSubType.Loop)
    const insideLoopNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const invisibleNodeId = generateUniqueNodeId(NodeType.Invisible)
    const closingNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const newLoopNode = createNewNodeStep(newLoopNodeId, newNodeData, newNodeData.type)
    const insideLoopNode = createNewNodePlaceholder(insideLoopNodeId, newNodeData, {}, true)
    const invisibleNode = createNewNodeInvisible(invisibleNodeId)
    const closingNode = createNewNodePlaceholder(
      closingNodeId,
      newNodeData,
      {
        loopResultType: LoopResultType.Close,
        scopeParentNodeId: newLoopNodeId
      },
      true
    )

    // 4
    let newEdges = [
      createEdge(EdgeType.Workflow, targetPlaceholderId, newLoopNode.id),
      createEdge(EdgeType.Loop, invisibleNode.id, closingNode.id),
      createEdge(EdgeType.Workflow, newLoopNode.id, insideLoopNode.id),
      createEdge(EdgeType.Workflow, newLoopNode.id, invisibleNode.id),
      createEdge(EdgeType.Workflow, insideLoopNode.id, closingNode.id)
    ]

    if (targetNodeChildId) {
      newEdges = newEdges.concat([createEdge(EdgeType.Workflow, closingNode.id, targetNodeChildId)])
    }

    wfModel.addNodesToGraph([newLoopNode, invisibleNode, closingNode, insideLoopNode])
    wfModel.addEdgesToGraph(newEdges)

    return true
  }
  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    return super.removeNode(wfModel, nodeId)
  }
}
