import { useQuery } from 'react-query'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { fetchWorkflowVersions } from 'src/services/workflows.api'

export type Version = {
  VersionId: string
  Key: string
  creator: string
  name: string
  LastModified: string
  IsLatest: boolean
}

export const useWFVersions = (wfid: string) => {
  const { isLoading, data, isError } = useQuery<Version[]>(
    ['workflows/versions', wfid],
    async () => fetchWorkflowVersions(wfid),
    {
      enabled: !!wfid,
      staleTime: LONG_STALE_TIME,
      retry: 0
    }
  )

  return { isLoading, data: data ?? [], isError }
}
