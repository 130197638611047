import { NodeSubType, NodeType } from 'src/common/constants/common.constants'
import { ApproveResultType, EdgeType } from '../wfe.constants'
import { createEdge, createNewNodePlaceholder, createNewNodeStep, generateUniqueNodeId } from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { ScopedFlowControlNodeStrategy } from './wfScopedFlowControlNodeStrategy'

export class ApproveFlowControlNodeStrategy extends ScopedFlowControlNodeStrategy {
  addNode(wfModel: WorkflowModel, targetPlaceholderId: string, newNodeData: any): boolean {
    const graph = wfModel.getGraph()

    // Find placeholder node neighbor so we could:
    // 1. Remove Edge from placeholder to next edge
    // 2. Add Edge from target node new placeholder to the neighbor node
    let targetNodeChildId = undefined
    const placeholderNodeNeighbors = graph.outNeighbors(targetPlaceholderId)

    if (placeholderNodeNeighbors.length === 1) {
      targetNodeChildId = placeholderNodeNeighbors[0]

      // Remove Edge from placeholder to next edge
      graph.dropEdge(targetPlaceholderId, targetNodeChildId)
    }

    const newApproveNodeId = generateUniqueNodeId(NodeSubType.Approve)
    const approveNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const rejectNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const closingNodeId = generateUniqueNodeId(NodeType.PassPlaceholder)
    const newStepNode = createNewNodeStep(newApproveNodeId, newNodeData, newNodeData.type)

    const rejectNode = createNewNodePlaceholder(
      rejectNodeId,
      newNodeData,
      {
        approveResultType: ApproveResultType.Reject
      },
      true
    )
    const approvedNode = createNewNodePlaceholder(
      approveNodeId,
      newNodeData,
      {
        approveResultType: ApproveResultType.Approve
      },
      true
    )
    const closingNode = createNewNodePlaceholder(
      closingNodeId,
      newNodeData,
      {
        approveResultType: ApproveResultType.Close,
        scopeParentNodeId: newApproveNodeId
      },
      true
    )

    let newEdges = [
      createEdge(EdgeType.Workflow, targetPlaceholderId, newStepNode.id),
      createEdge(EdgeType.ApproveResult, newApproveNodeId, rejectNode.id, {
        approveResultType: ApproveResultType.Reject
      }),
      createEdge(EdgeType.ApproveResult, newApproveNodeId, approvedNode.id, {
        approveResultType: ApproveResultType.Approve
      }),
      createEdge(EdgeType.Workflow, approvedNode.id, closingNode.id),
      createEdge(EdgeType.Workflow, rejectNode.id, closingNode.id)
    ]

    if (targetNodeChildId) {
      newEdges = newEdges.concat([createEdge(EdgeType.Workflow, closingNode.id, targetNodeChildId)])
    }

    wfModel.addNodesToGraph([newStepNode, rejectNode, approvedNode, closingNode]) // 5
    wfModel.addEdgesToGraph(newEdges) // 6

    return true
  }

  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    return super.removeNode(wfModel, nodeId)
  }
}
