export const hasDynamicOutput = (value: string) => value.includes('{{$.steps')

export const dynamicOutputToBE = (value: string | object): any => {
  if (typeof value === 'object') {
    const json = JSON.stringify(value ?? '')

    return JSON.parse(dynamicOutputToBE(json))
  }

  if (typeof value === 'string') {
    return (value ?? '').replaceAll('{{', '{{$.steps.').replaceAll('{{$.steps.integrations', '{{$.integrations')
  }

  return value
}

export const dynamicOutputToUI = (value: string | object): any => {
  if (typeof value === 'object') {
    const json = JSON.stringify(value ?? '')

    return JSON.parse(dynamicOutputToUI(json))
  }

  if (typeof value === 'string') {
    return (value ?? '').replaceAll('$.steps.', '').replaceAll('$.integrations.', 'integrations.')
  }

  return value
}
