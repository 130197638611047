import { MultiDirectedGraph } from 'graphology'
import { NodeScope } from 'src/common/constants/common.constants'
import { IWorkflowModel } from './wfe.types'
import { NodeStrategyFactory } from './strategies/wfStrategyFactory'
import { LoopResultType } from './wfe.constants'

export class WorkflowModel implements IWorkflowModel {
  private graph: MultiDirectedGraph

  constructor() {
    const options = {
      allowSelfLoops: true
    }
    this.graph = new MultiDirectedGraph(options)
    this.graph.clear()
  }

  public getNumOfNodes() {
    return this.graph.order
  }

  public addNodesToGraph(nodes: any[]) {
    for (const node of nodes) {
      this.graph.addNode(node.id, { ...node })
    }
  }

  public addEdgesToGraph(edges: any[]) {
    for (const edge of edges) {
      this.graph.addEdge(edge.source, edge.target, { ...edge })
    }
  }

  getNodes(): any[] {
    return this.graph.mapNodes((node, attr) => {
      return { id: node, ...attr }
    })
  }

  getEdges(): any[] {
    return this.graph.mapEdges((edge, attr) => {
      return { id: edge, ...attr }
    })
  }

  public getGraph(): MultiDirectedGraph {
    return this.graph
  }

  public initialize(nodes: any[], edges: any[], takeSnapshot?: () => void): void {
    takeSnapshot?.()
    this.addNodesToGraph(nodes)
    this.addEdgesToGraph(edges)
  }

  private updateNewNodeScope(targetNodeId: string, newNodeData: any) {
    const targetNode = this.graph.getNodeAttributes(targetNodeId)

    // In case we're already in a different scope from the default scope such as If or Loop scope
    // we'll want to update the new node data to it
    if (
      targetNode.data.scope !== NodeScope.Default &&
      newNodeData.scope === NodeScope.Default &&
      targetNode.data.loopResultType !== LoopResultType.Close
    ) {
      newNodeData.scope = targetNode.data.scope
      newNodeData.scopeParentNodeId = targetNode.data.scopeParentNodeId
    }
  }

  public addNode(targetPlaceholderId: string, newNodeData: any): boolean {
    // New Node Scope inheritence enforcement
    this.updateNewNodeScope(targetPlaceholderId, newNodeData)

    // Apply strategy
    const strategy = NodeStrategyFactory.createStrategy(newNodeData)

    return strategy.addNode(this, targetPlaceholderId, newNodeData, newNodeData.step?.subgroup)
  }

  public removeNode(nodeId: string): boolean {
    const nodeData = this.graph.getNodeAttribute(nodeId, 'data')
    const strategy = NodeStrategyFactory.createStrategy(nodeData)

    return strategy.removeNode(this, nodeId)
  }
}
