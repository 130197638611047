import { useFeatureFlag } from 'src/common/hooks/useFeatureFlag'
import { WorkflowMode } from '../wfe.types'

export const useIsEditableWorkflow = (workflow: any) => {
  const editSystemWorkflows = useFeatureFlag('editSystemWorkflows')

  if (!workflow) return false

  return editSystemWorkflows || workflow?.workflowMode !== WorkflowMode.SYSTEM
}
