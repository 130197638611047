// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import GroupsIcon from '@mui/icons-material/Groups'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

// ** Type Imports
import { Button, Dialog, DialogActions, DialogContent, Link } from '@mui/material'
import { useUser, useLogoutFunction } from '@propelauth/nextjs/client'
import { IFRAME_PERMISSIONS } from 'src/common/constants/common.constants'
import { Flex } from 'src/components/styled-components/flex'
import { useSettings } from 'src/common/hooks/useSettings'
import { useCanPerformAction } from 'src/common/auth/auth.hooks'
import { Permission } from 'src/common/auth/constants'

const USERS_DIALOG_STYLE = {
  width: '60vw',
  height: '60vh',
  overflow: 'hidden'
}

const USERS_IFRAME_STYLE = {
  width: '100%',
  height: '100%',
  border: '0px'
}

// ** Styled Components
const UserDropdown = () => {
  const logoutFn = useLogoutFunction()
  const { settings } = useSettings()
  const { user } = useUser()

  const canCreateOrg = useCanPerformAction(Permission['org:create'])
  const canSeeOrgMembers = useCanPerformAction(Permission['org:members'])
  const canSeeOrgSettings = useCanPerformAction(Permission['org:settings'])
  const canPerformOrgAction = canCreateOrg || canSeeOrgMembers || canSeeOrgSettings

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [dialogOpenURL, setDialogOpenURL] = useState('')

  const getIframeSrc = () => {
    const authUrl = process.env.NEXT_PUBLIC_AUTH_URL ?? ''
    const url = `${authUrl}/${dialogOpenURL}`
    if (user?.activeOrgId && url.includes('org/')) {
      return `${url}/${user?.activeOrgId}`
    }

    return url
  }

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary'
    }
  }

  const handleLogout = () => {
    window.Intercom('shutdown')
    logoutFn()
    handleDropdownClose()
  }

  const userAvatar = user?.pictureUrl ?? '/images/avatars/1.png'

  return (
    <Fragment>
      <Avatar
        alt={``}
        onClick={handleDropdownOpen}
        sx={{ width: 30, height: 30, cursor: 'pointer' }}
        src={userAvatar}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 2 }}>
          <Flex>
            <Avatar alt='John Doe' src={userAvatar} sx={{ width: '2.5rem', height: '2.5rem' }} />
            <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600 }}>{``}</Typography>
              <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                <Link
                  onClick={e => {
                    setDialogOpenURL('account')
                    e.stopPropagation()
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {user?.email}
                </Link>
              </Typography>
            </Box>
          </Flex>
        </Box>

        {canPerformOrgAction && <Divider sx={{ mt: '0 !important' }} />}

        {canPerformOrgAction && (
          <div>
            <b style={{ display: 'block', marginTop: '4px', marginLeft: '16px' }}>Organization</b>
            {canSeeOrgMembers && (
              <MenuItem sx={{ p: 0 }} onClick={handleDropdownClose}>
                <Box
                  sx={styles}
                  onClick={e => {
                    setDialogOpenURL('org/members')
                    e.stopPropagation()
                  }}
                >
                  <GroupsIcon />
                  Members
                </Box>
              </MenuItem>
            )}
            {canSeeOrgSettings && (
              <MenuItem sx={{ p: 0 }} onClick={handleDropdownClose}>
                <Box
                  sx={styles}
                  onClick={e => {
                    setDialogOpenURL('org/settings')
                    e.stopPropagation()
                  }}
                >
                  <SettingsIcon />
                  Settings
                </Box>
              </MenuItem>
            )}
            {canCreateOrg && (
              <MenuItem sx={{ p: 0 }} onClick={handleDropdownClose}>
                <Box
                  sx={styles}
                  onClick={e => {
                    setDialogOpenURL('create_org')
                    e.stopPropagation()
                  }}
                >
                  <OpenInNewIcon />
                  Create Organization
                </Box>
              </MenuItem>
            )}
          </div>
        )}

        <Divider />
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <LogoutIcon />
          Logout
        </MenuItem>
      </Menu>

      <Dialog open={!!dialogOpenURL} maxWidth={false} onClose={() => setDialogOpenURL('')}>
        <DialogContent sx={USERS_DIALOG_STYLE}>
          <iframe {...IFRAME_PERMISSIONS} src={getIframeSrc()} style={USERS_IFRAME_STYLE}></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpenURL('')}>OK</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default UserDropdown
