import { NavLink } from 'src/common/layouts/types'
import { Permission } from 'src/common/auth/constants'

import DashboardsIcon from 'public/images/icons/main/dashboards.svg'
import WorkflowsIcon from 'public/images/icons/main/workflows.svg'
import TemplatesIcon from 'public/images/icons/main/templates.svg'
import DatastoresIcon from 'public/images/icons/main/datastores.svg'
import RunsIcon from 'public/images/icons/main/runs.svg'
import IntegrationsIcon from 'public/images/icons/main/integrations.svg'
import CasesIcon from 'public/images/icons/main/opportunities.svg'
import HomeIcon from 'public/images/icons/main/home.svg'
import ApprovalsIcon from 'public/images/icons/main/approvals.svg'
import CasesConfigurationIcon from 'public/images/icons/main/cases-configuration.svg'

export const routes = {
  home: {
    path: '/home',
    permission: Permission['home:read']
  },
  opportunities: {
    path: '/cases/all',
    firstPagePath: '/cases',
    permission: Permission['recommendations:read']
  },
  boards: {
    path: '/boards',
    permission: Permission['recommendations:read']
  },
  casesConfiguration: {
    flag: 'casesconfigurations',
    path: '/cases-configuration',
    permission: Permission['recommendations:read']
  },
  dashboards: {
    path: '/dashboards',
    permission: Permission['dashboards:read']
  },
  workflows: {
    path: '/workflows',
    permission: Permission['wf:read']
  },
  wfe: {
    path: '/wfe',
    permission: Permission['wf:read']
  },
  templates: {
    path: '/templates',
    permission: Permission['templates:read']
  },
  datastores: {
    path: '/datastores',
    permission: Permission['datastores:read']
  },
  runs: {
    path: '/runs',
    permission: Permission['activityLog:read']
  },
  approvals: {
    path: '/approvals',
    permission: Permission['approvals:read']
  },
  integrations: {
    path: '/integrations',
    permission: Permission['integrations:read']
  },
  forms: {
    path: '/forms'
  }
}

export const navigationItems = [
  {
    icon: HomeIcon,
    title: 'Home',
    ...routes.home
  },
  {
    title: 'Cases',
    icon: CasesIcon,
    ...routes.boards
  },
  {
    title: 'Cases Configuration',
    icon: CasesConfigurationIcon,
    ...routes.casesConfiguration,
    flag: 'casesconfigurations'
  },
  {
    title: 'Dashboards',
    icon: DashboardsIcon,
    ...routes.dashboards
  },
  {
    title: 'Workflows',
    icon: WorkflowsIcon,
    ...routes.workflows
  },
  {
    title: 'Templates',
    icon: TemplatesIcon,
    ...routes.templates
  },
  {
    title: 'Datastores',
    icon: DatastoresIcon,
    ...routes.datastores
  },
  {
    title: 'Runs',
    icon: RunsIcon,
    ...routes.runs
  },
  {
    title: 'Approvals',
    icon: ApprovalsIcon,
    ...routes.approvals
  },
  {
    title: 'Integrations',
    icon: IntegrationsIcon,
    ...routes.integrations
  }
] as NavLink[]

export const casesByBoardPath = (boardId: string) => {
  return routes.opportunities.firstPagePath + '/' + boardId
}
