// ** MUI Imports
import { Theme } from '@mui/material/styles'

export const SELECT_STYLE = { padding: '8px 12px' }

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Select = (theme: Theme) => {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': SELECT_STYLE
        }
      }
    }
  }
}

export default Select
