import { useQuery } from 'react-query'
import { Workflow } from 'src/common/types/common.types'
import { fetchWorkflows } from 'src/services/workflows.api'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'

export const useWorkflowsNames = (): string[] => {
  const { data: workflows, isLoading, error } = useQuery('workflows', fetchWorkflows, { staleTime: LONG_STALE_TIME })
  const workflowNames = !isLoading && !error ? workflows.map((w: Workflow) => w.name) : []

  return workflowNames
}
