import { useQuery } from 'react-query'
import { VERY_LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { fetchWorkflowVersion } from 'src/services/workflows.api'

export const useWFVersion = (wfid: string, versionId: string) => {
  const { isLoading, data } = useQuery(
    ['workflows/versions', wfid, versionId],
    async () => fetchWorkflowVersion(wfid, versionId),
    {
      staleTime: VERY_LONG_STALE_TIME,
      enabled: !!wfid && !!versionId
    }
  )

  return { isLoading, data }
}
