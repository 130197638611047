import { NodeType } from '../../common/constants/common.constants'
import { WFEState } from './wfe.store'

const MAX_HISTORY_SIZE = 100
export const INVALID_NODE = { type: NodeType.Invalid }

type WFEditorStateUpdater = {
  get: () => WFEState
  set: (changes: Partial<WFEState>) => void
}

export const redo =
  ({ get, set }: WFEditorStateUpdater) =>
  () => {
    const futureState = get().future[get().future.length - 1]

    if (futureState) {
      const { getNodes, getEdges, setNodes, setEdges } = get().reactFlowInstance
      set({
        future: get().future.slice(0, get().future.length - 1),
        past: [...get().past, { nodes: getNodes(), edges: getEdges() }],
        focusedNode: INVALID_NODE
      })

      setNodes(futureState.nodes)
      setEdges(futureState.edges)
    }
  }

export const undo =
  ({ get, set }: WFEditorStateUpdater) =>
  () => {
    const pastState = get().past[get().past.length - 1]
    if (!pastState) {
      return
    }

    const { getNodes, getEdges, setNodes, setEdges } = get().reactFlowInstance
    if (pastState) {
      set({
        past: get().past.slice(0, get().past.length - 1),
        future: [...get().future, { nodes: getNodes(), edges: getEdges() }],
        focusedNode: INVALID_NODE
      })
    }
    setNodes(pastState.nodes)
    setEdges(pastState.edges)
  }

export const takeSnapshot =
  ({ get, set }: WFEditorStateUpdater) =>
  () => {
    const snapshot = {
      nodes: JSON.parse(JSON.stringify(get().nodes)),
      edges: JSON.parse(JSON.stringify(get().edges))
    }
    set({ past: [...get().past.slice(-MAX_HISTORY_SIZE + 1), snapshot], future: [] })
  }

export const stepDisplayName = (name = '') => name.replaceAll('_', ' ')
