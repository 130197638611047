import { NodeType } from 'src/common/constants/common.constants'
import { EdgeType } from '../wfe.constants'
import { NO_ID, createEdge, createNewNodePlaceholder, createNewNodeStep, generateUniqueNodeId } from '../wfe.helper'
import { WorkflowModel } from '../wfe.model'
import { NodeStrategy } from './wfNodeStrategy'

export class BasicNodeStrategy implements NodeStrategy {
  addNode(wfModel: WorkflowModel, targetPlaceholderId: string, newNodeData: any): boolean {
    const graph = wfModel.getGraph()

    // Find placeholder node neighbor so we could:
    // 1. Remove Edge from placeholder to next edge
    // 2. Add Edge from target node new placeholder to the neighbor node
    let targetNodeChildId = undefined
    const placeholderNodeNeighbors = graph.outNeighbors(targetPlaceholderId)

    // There should be only one neighbor
    if (placeholderNodeNeighbors.length > 1) {
      console.error('Somethings wrong - more than 1 placeholder node childs')
    }

    targetNodeChildId = placeholderNodeNeighbors[0]
    if (targetNodeChildId) {
      graph.dropEdge(targetPlaceholderId, targetNodeChildId)
    }

    // Add the new node and related structure after the target placeholder node
    const newStepUniqueNodeId =
      newNodeData.id === NO_ID ? generateUniqueNodeId(newNodeData.step.subgroup) : newNodeData.id

    const newPlaceHolderUniqueNodeId = generateUniqueNodeId(NodeType.Placeholder)

    // New Nodes Addition
    const newNodes = [
      createNewNodeStep(newStepUniqueNodeId, newNodeData, newNodeData.type),
      createNewNodePlaceholder(newPlaceHolderUniqueNodeId, newNodeData)
    ]
    wfModel.addNodesToGraph(newNodes)

    // New Edges Addition
    let newEdges = [
      createEdge(EdgeType.Workflow, targetPlaceholderId, newStepUniqueNodeId),
      createEdge(EdgeType.Workflow, newStepUniqueNodeId, newPlaceHolderUniqueNodeId)
    ]
    if (targetNodeChildId) {
      newEdges = newEdges.concat([createEdge(EdgeType.Workflow, newPlaceHolderUniqueNodeId, targetNodeChildId)])
    }
    wfModel.addEdgesToGraph(newEdges)

    return true
  }

  removeNode(wfModel: WorkflowModel, nodeId: string): boolean {
    // Taking advantage of graphology library dropNode action which removes a node and all of its edges,
    // 1. We will first want to take note of the parent node of the target node
    // 2. (If exists) the child node of the target node placeholder child.
    // 3. We will remove the target node and their child placeholder node
    // 4. We will connect the parent of the target node and the child of the deleted placeholder child if it exists

    // Retrieving parent and child
    const graph = wfModel.getGraph()
    const placeholderParent = graph.inNeighbors(nodeId)[0] // there can only be one
    const placeholderChild = graph.outNeighbors(nodeId)[0] // there can only be one
    const nodeGrandchild = graph.outNeighbors(placeholderChild)[0] // there can only be one

    graph.dropNode(nodeId)
    graph.dropNode(placeholderChild)

    if (nodeGrandchild) {
      wfModel.addEdgesToGraph([createEdge(EdgeType.Workflow, placeholderParent, nodeGrandchild)])
    }

    return true
  }
}
