import React from 'react'
import cx from 'classnames'
import styles from './index.module.css'

type ToggleProps = {
  size?: 'medium' | 'large'
  isDisabled?: boolean
  onClick?: (e?: any) => void
  isToggled?: boolean
  className?: string
}

const Toggle = ({ isToggled, size = 'medium', isDisabled, onClick, className }: ToggleProps) => {
  return (
    <div
      className={cx(styles.toggle, styles[size], className, {
        [styles.disabled]: isDisabled,
        [styles.toggled]: isToggled
      })}
      onClick={!isDisabled ? onClick : undefined}
    >
      <div className={styles.circle}></div>
    </div>
  )
}

export default React.memo(Toggle)
