import { Execution } from '../../modules/wfe/wfe.types'
import { FailedStatuses, PendingStatuses } from '../../common/utils/workflows'
import { WorkflowRunStatuses } from '../../modules/monitoring/components/types'
import { strings } from '../../common/constants/strings'

const i18n = strings.wfeHeader

export const isErrorMessage = (message: string) => message && message !== 'Step Failed: None'

const StatusesLabels: any = {
  [WorkflowRunStatuses.FAILED]: i18n.runFailed,
  [WorkflowRunStatuses.WAITING]: strings.common.running,
  [WorkflowRunStatuses.SUCCEEDED]: i18n.runSuccess,
  [WorkflowRunStatuses.RUNNING]: strings.common.running,
  [WorkflowRunStatuses.ABORTED]: strings.common.stopped
}

export const getSummaryExecutionTitle = (isRunningSteps: boolean, status: string) => {
  const baseTitle = isRunningSteps ? strings.common.step : strings.common.workflow

  return `${baseTitle} ${StatusesLabels[status] || strings.common.running}`
}
export const getSummaryExecutionsStatus = (steps: Execution[]) => {
  const foundStatuses: any = {}
  for (const step of steps) {
    if (WorkflowRunStatuses.RUNNING === step.status) {
      return WorkflowRunStatuses.RUNNING
    }

    if (FailedStatuses.includes(step.status || '')) {
      foundStatuses[WorkflowRunStatuses.FAILED] = true
    } else if (PendingStatuses.includes(step.status || '')) {
      foundStatuses[WorkflowRunStatuses.WAITING] = true
    }
    foundStatuses[step.status || ''] = true
  }

  if (foundStatuses[WorkflowRunStatuses.FAILED]) {
    return WorkflowRunStatuses.FAILED
  } else if (foundStatuses[WorkflowRunStatuses.WAITING]) {
    return WorkflowRunStatuses.WAITING
  }

  return WorkflowRunStatuses.SUCCEEDED
}
