import { FlowControlType } from '../../../../../common/constants/common.constants'
import { strings } from '../../../../../common/constants/strings'
import styles from './style.module.css'

const FLOW_CONTROLS_ICON_FOLDER = 'icons/flowcontrols'
export const getNodeProps = (type: FlowControlType) => {
  if (type !== FlowControlType.Comment && Object.values(FlowControlType).includes(type)) {
    return {
      ...FlowControlNodesCommonProps,
      ...FlowControlNodesProps[type],
      iconProps: {
        ...FlowControlNodesCommonProps.iconProps,
        ...FlowControlNodesProps[type].iconProps
      }
    }
  }
}

export const StepParametersStatusIconProps: any = {
  fresh: { name: 'stepSuccess', tooltipProps: { title: strings.workflows.parameterStatus.fresh } },
  outdated: { name: 'stepWarning', tooltipProps: { title: strings.workflows.parameterStatus.outdated } },
  missing: {
    name: 'stepFail',
    width: 17,
    height: 17,
    tooltipProps: { title: strings.workflows.parameterStatus.missing }
  },
  loading: {
    name: 'running',
    className: styles.rotatingSvg,
    tooltipProps: { title: strings.workflows.parameterStatus.loading }
  },
  error: { name: 'stepFail', tooltipProps: { title: strings.workflows.parameterStatus.error } }
}

export const FlowControlNodesCommonProps = {
  upperTitle: strings.workflows.flowControlsNodes.upperTitle,
  iconProps: {
    height: 40,
    width: 40,
    backgroundColor: ''
  }
}

export const FlowControlNodesProps = {
  [FlowControlType.Break]: {
    title: strings.workflows.flowControlsNodes.break,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Break.toLowerCase()}Node.svg`
    },
    backgroundColor: 'purple'
  },
  [FlowControlType.If]: {
    title: strings.workflows.flowControlsNodes.if,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.If.toLowerCase()}Node.svg`
    },
    backgroundColor: 'yellow'
  },
  [FlowControlType.Exit]: {
    title: strings.workflows.flowControlsNodes.exit,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Exit.toLowerCase()}Node.svg`
    },
    backgroundColor: 'blueSky'
  },
  [FlowControlType.SubWorkflow]: {
    title: strings.workflows.flowControlsNodes.flow,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/flowNode.svg`
    },
    backgroundColor: 'violet'
  },
  [FlowControlType.Loop]: {
    title: strings.workflows.flowControlsNodes.loop,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Loop.toLowerCase()}Node.svg`
    },
    backgroundColor: 'purple'
  },
  [FlowControlType.Collect]: {
    title: strings.workflows.flowControlsNodes.collect,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Collect.toLowerCase()}Node.svg`
    },
    backgroundColor: 'purple'
  },
  [FlowControlType.Trigger]: {
    title: strings.workflows.flowControlsNodes.trigger,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Trigger.toLowerCase()}.svg`
    },
    backgroundColor: 'blue'
  },
  [FlowControlType.Wait]: {
    title: strings.workflows.flowControlsNodes.wait,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Wait.toLowerCase()}Node.svg`
    },
    backgroundColor: 'babyBlue'
  },
  [FlowControlType.Approve]: {
    title: strings.workflows.flowControlsNodes.approve,
    iconProps: {
      path: `/images/${FLOW_CONTROLS_ICON_FOLDER}/${FlowControlType.Approve.toLowerCase()}Node.svg`
    },
    backgroundColor: 'green'
  },
  [FlowControlType.Comment]: undefined
}
