import React, { useEffect, useRef, useState } from 'react'
import Icon from '../Icon'
import { WithHoverProps } from '../WithHover'
import { useMouseClickOutside } from '../../modules/wfe/hooks/useMouseClickOutside'

type ArrowExpanderProps = {
  withHoverProps: WithHoverProps
  isExternalShowHoverComponent: boolean
  setIsExternalShowHoverComponent?: (isShowHoverComponent: boolean) => void
}

const ArrowExpander = ({
  withHoverProps,
  isExternalShowHoverComponent,
  setIsExternalShowHoverComponent
}: ArrowExpanderProps) => {
  const componentToShowRef = useRef<any>()
  const [isShowHoverComponent, setIsShowHoverComponent] = useState(isExternalShowHoverComponent)

  // Update local state when external state changes
  useEffect(() => {
    setIsShowHoverComponent?.(isExternalShowHoverComponent)
  }, [isExternalShowHoverComponent])

  // Close hover component if clicked outside
  useMouseClickOutside(
    componentToShowRef,
    () => {
      if (isShowHoverComponent || isExternalShowHoverComponent) {
        setIsShowHoverComponent(false)
        setIsExternalShowHoverComponent?.(false)
      }
    },
    'mousedown'
  )

  return (
    <div>
      <Icon
        onIconClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (setIsExternalShowHoverComponent) {
            setIsExternalShowHoverComponent(!isExternalShowHoverComponent)
          } else {
            setIsShowHoverComponent(!isShowHoverComponent)
          }
        }}
        color={isExternalShowHoverComponent ? 'var(--neutralBrandBlue60)' : undefined}
        hoverColor={!isExternalShowHoverComponent ? 'var(--neutralBrandBlue60)' : undefined}
        name='chevron-down'
        backgroundColor={isExternalShowHoverComponent ? 'var(--lightBg)' : undefined}
        hoverBackgroundColor={!isExternalShowHoverComponent ? 'var(--lightBg)' : undefined}
        backgroundWidth={24}
        backgroundHeight={24}
        withHoverProps={{
          isShowHoverComponent: isExternalShowHoverComponent || isShowHoverComponent,
          gap: 8,
          contentRef: componentToShowRef,
          style: { zIndex: 1202 },
          ...withHoverProps
        }}
      />
    </div>
  )
}

export default ArrowExpander
