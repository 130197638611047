import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import { FlowControlNodesProps } from '../../modules/wfe/components/NodeTypes/WorkflowNode/utils'
import { FlowControlType, IntegrationType, NodeControlType, UtilityType } from './common.constants'

export const IntegrationsIcons: { [key in IntegrationType]: string } = {
  [IntegrationType.Slack]: '/images/icons/integrations/slack.png',
  [IntegrationType.Teams]: '/images/icons/integrations/teams.png',
  [IntegrationType.Email]: '/images/icons/integrations/email.svg',
  [IntegrationType.AWS]: '/images/icons/integrations/aws.png',
  [IntegrationType.Jira]: '/images/icons/integrations/jira.png',
  [IntegrationType.AnodotCost]: '/images/icons/integrations/anodotcost.svg',
  [IntegrationType.GitHub]: '/images/icons/integrations/github.svg',
  [IntegrationType.GCP]: '/images/icons/integrations/gcp.png',
  [IntegrationType.Azure]: '/images/icons/integrations/azure.png'
}

export const FlowControlIcons: { [key in FlowControlType]: string } = {
  [FlowControlType.Break]: FlowControlNodesProps[FlowControlType.Break].iconProps.path,
  [FlowControlType.Collect]: FlowControlNodesProps[FlowControlType.Collect].iconProps.path,
  [FlowControlType.If]: FlowControlNodesProps[FlowControlType.If].iconProps.path,
  [FlowControlType.Exit]: FlowControlNodesProps[FlowControlType.Exit].iconProps.path,
  [FlowControlType.SubWorkflow]: FlowControlNodesProps[FlowControlType.SubWorkflow].iconProps.path,
  [FlowControlType.Loop]: FlowControlNodesProps[FlowControlType.Loop].iconProps.path,
  [FlowControlType.Trigger]: FlowControlNodesProps[FlowControlType.Trigger].iconProps.path,
  [FlowControlType.Wait]: FlowControlNodesProps[FlowControlType.Wait].iconProps.path,
  [FlowControlType.Approve]: FlowControlNodesProps[FlowControlType.Approve].iconProps.path,
  [FlowControlType.Comment]: '/images/svg/comment.svg'
}

export const UtlitiesIcons: { [key in any]: string } = {
  [UtilityType.Workflow]: '/images/icons/utilities/workflow.svg',
  [UtilityType.Array]: '/images/icons/utilities/array.svg',
  [UtilityType.Api]: '/images/icons/utilities/api.svg',
  [UtilityType.Format]: '/images/icons/utilities/format.svg',
  [UtilityType.Network]: '/images/icons/utilities/network.svg',
  [UtilityType.Math]: '/images/icons/utilities/math.svg',
  [UtilityType.Variable]: '/images/icons/utilities/variableUtility.svg',
  [UtilityType.Code]: '/images/icons/utilities/code.svg',
  [UtilityType.Datastore]: '/images/icons/utilities/datastore.svg',
  [UtilityType.Recommendation]: '/images/icons/utilities/recommendation.svg',
  RECOMMENDATION: '/images/icons/utilities/recommendation.svg', // Temp workfaround until all references are converted
  [UtilityType.AnodotCost]: '/images/icons/integrations/anodotcost.svg'
}

export const WFSubGroupIcons: {
  [key in IntegrationType | FlowControlType]: string
} = { ...IntegrationsIcons, ...FlowControlIcons, ...UtlitiesIcons }

export const NodeControlIcon: { [key in NodeControlType]: string } = {
  [NodeControlType.Execute]: 'PublishedWithChanges',
  [NodeControlType.Delete]: 'DeleteOutline',
  [NodeControlType.Copy]: 'ContentCopy',
  [NodeControlType.Cut]: 'ContentCut',
  [NodeControlType.Comment]: (<AddCommentOutlinedIcon sx={{ color: 'white', height: 16, width: 16 }} />) as any
}
