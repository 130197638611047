import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

export const ConfirmRunDialog = ({
  open,
  onRun,
  onClose
}: {
  open: boolean
  onRun: () => void
  onClose: () => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Run workflow confirmation</DialogTitle>
      <DialogContent>
        This workflow has errors and might not work as expected. You can still run it, but please be aware of possible
        issues.
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={async () => {
            await onRun()
            onClose()
          }}
        >
          Run with Errors
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
