export enum Roles {
  Admin = 'Admin',
  Member = 'Member',
  'MSP-Admin' = 'MSP-Admin',
  'MSP-Customer-Admin' = 'MSP-Customer-Admin'
}

export enum Permission {
  'org:create' = 'org:create',
  'org:members' = 'org:members',
  'org:settings' = 'org:settings',
  'wf:read' = 'wf:read',
  'wf:create' = 'wf:create',
  'templates:read' = 'templates:read',
  'integrations:read' = 'integrations:read',
  'datastores:read' = 'datastores:read',
  'approvals:read' = 'approvals:read',
  'activityLog:read' = 'activityLog:read',
  'recommendations:read' = 'recommendations:read',
  'dashboards:read' = 'dashboards:read',
  'dashboards:edit' = 'dashboards:edit',
  'home:read' = 'home:read'
}
