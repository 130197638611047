// ** Type Imports
import { PaletteMode } from '@mui/material'
import { Skin } from 'src/common/layouts/types'

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  // ** Vars
  const whiteColor = '#FFF'
  const lightColor = '76, 78, 100'
  const darkColor = '234, 234, 255'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return whiteColor
    } else if (skin === 'bordered' && mode === 'dark') {
      return '#30334E'
    } else if (mode === 'light') {
      return '#F7F7F9'
    } else return '#282A42'
  }

  return {
    boxShadow: '2px 4px 20px 0 rgba(39, 29, 67, 0.08)',
    customColors: {
      dark: `rgb(${darkColor})`,
      main: `rgb(${mainColor})`,
      light: `rgb(${lightColor})`,
      darkRaw: darkColor,
      mainRaw: mainColor,
      lightRaw: lightColor,
      darkBg: '#282A42',
      lightBg: '#F7F7F9',
      bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
      trackBg: mode === 'light' ? '#F2F2F4' : '#41435C',
      tooltipBg: mode === 'light' ? '#262732' : '#464A65',
      tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B',
      royalBlue: '#9246FF',
      brandBlue: '#2D1880',
      primaryBlueBrand: '#051241',
      mainGrey: '#6D7A83',
      secondaryDarkPurple: '#422F8D',
      secondaryPurple: '#574699',
      neutralBrandBlue5: '#F2F3F5',
      neutralBrandBlue10: '#E6E7EC',
      neutralBrandBlue20: '#CDD0D9',
      neutralBrandBlue30: '#B4B8C6',
      neutralBrandBlue40: '#9BA0B3',
      neutralBrandBlue60: '#69718D',
      neutralBrandBlue80: '#374167',
      brandBlueLight: '#60529A',
      mint: '#50BAB6',
      red: '#D84432',
      placeholder: '#999',
      purplishGrey: '#8B81B1',
      lavenderBlush: '#FFEBF3',
      warning: '#FFB076',
      pink: '#FF86B6',
      lightPink: '#FF5C9D',
      lightSlateBlue: '#5C8CFF',
      lightSlateBlue20: '#5C8CFF33'
    },
    charts: {
      lines: {
        0: '#5C8CFF',
        1: '#5CE2FF'
      },
      xAxis: '#69718D',
      yAxis: '#787878',
      grid: '#f0f0f0',

      trend: {
        up: '#16A67A',
        down: '#C13A3A',
        zero: '#999'
      }
    },
    opportunities: {
      open: '#574699',
      in_progress: '#FF5C9D',
      completed: '#1CCF99',
      excluded: '#B4B8C6'
    },
    statuses: {
      success: {
        color: '#0d625e',
        border: '#50bab6',
        background: '#CBF5F3',
        pie: '#34C0A7'
      },
      running: {
        color: '#6040AD',
        border: '#9246FF',
        background: '#DBDBFF',
        pie: '#DBDBFF'
      },
      error: {
        color: '#d700a6',
        border: '#ff67df',
        background: '#ffe7fa',
        critical: '#D84432',
        pie: '#DA3264'
      },
      partially: {
        color: '#e3a209',
        border: '#ebdf9f',
        background: '#fff4d9',
        pie: '#FFA800'
      },
      waiting: {
        color: '#e3a209',
        border: '#ebdf9f',
        background: '#fff4d9',
        pie: '#FFA800'
      },
      timedOut: {
        color: '#9E9E9E',
        border: '#ccc',
        critical: '#9E9E9E',
        background: '#eee',
        pie: '#9E9E9E'
      }
    },
    mode: mode,
    common: {
      black: '#000',
      white: whiteColor
    },
    primary: {
      light: '#fff',
      main: '#FF5C9D',
      dark: '#5A5FE0',
      contrastText: whiteColor
    },
    secondary: {
      light: '#7F889B',
      main: '#6D788D',
      dark: '#606A7C',
      contrastText: whiteColor
    },
    error: {
      light: '#FF625F',
      main: '#FF4D49',
      dark: '#E04440',
      contrastText: whiteColor
    },
    warning: {
      light: '#FDBE42',
      main: '#FDB528',
      dark: '#DF9F23',
      contrastText: whiteColor
    },
    info: {
      light: '#40CDFA',
      main: '#26C6F9',
      dark: '#21AEDB',
      contrastText: whiteColor
    },
    success: {
      light: '#83E542',
      main: '#72E128',
      dark: '#64C623',
      contrastText: whiteColor
    },
    grey: {
      10: '#FAF9FF',
      50: '#EEEEFD',
      100: '#E5E6FA',
      200: '#D5D5EF',
      250: '#C0BBD3',
      300: '#9F9FC6',
      400: '#7574A3',
      500: '#515083',
      600: '#3B3A68',
      700: '#292856',
      800: '#1D1C46'
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.6)`,
      disabled: `rgba(${mainColor}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? whiteColor : '#30334E',
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.05)`,
      hoverOpacity: 0.05,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
