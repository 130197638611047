// ** MUI Imports
import { Theme } from '@mui/material/styles'
import { RESPONSIVE_FONT_SIZE } from 'src/common/constants/ui-components.constants'

const getBorder = (size: number) => size + 'px solid rgba(0,0,0,0.1) !important'

const styleOverrides = (theme: Theme) => {
  return {
    root: {
      border: 0,
      color: theme.palette.customColors.primaryBlueBrand,
      '& *:focus, & *:focus-within': {
        outline: 'none !important'
      },

      '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
        borderBottom: getBorder(1),
        fontSize: RESPONSIVE_FONT_SIZE
      },

      '& .MuiDataGrid-footerContainer': {
        borderTop: getBorder(1)
      },

      '& .MuiDataGrid-cell:focus, & .MuiTableCell:focus': {
        outline: 'none',
        fontSize: ''
      },
      '& .MuiDataGrid-columnHeaderTitle, & .MuiTableCell-head': {
        color: `${theme.palette.customColors.neutralBrandBlue40} !important`,
        fontWeight: 400,
        fontSize: RESPONSIVE_FONT_SIZE
      },
      '&:not(.disableRowSelectionOnClick) .MuiDataGrid-row:hover': {
        cursor: 'pointer',
        backgroundColor: 'var(--lightGrey)',
        '.MuiDataGrid-cell:first-of-type': {
          textDecoration: 'underline !important'
        }
      },
      '& .MuiTablePagination-toolbar': {
        '& .MuiTablePagination-displayedRows': {
          fontSize: '12px !important',
          color: `${theme.palette.customColors.primaryBlueBrand} !important`
        },
        '& .MuiTablePagination-actions': {
          marginLeft: '4px !important'
        }
      }
    },
    toolbarContainer: {
      padding: '8px 0px 0px 0px !important'
    }
  }
}

const styleOverridesTable = (theme: Theme) => {
  const style = styleOverrides(theme)

  return {
    ...style,
    root: {
      ...style.root,
      '& .MuiTableCell-root': {
        borderBottom: getBorder(1),
        color: theme.palette.customColors.primaryBlueBrand,
        fontSize: RESPONSIVE_FONT_SIZE
      },

      '& .MuiTableCell-head': {
        borderBottom: getBorder(1),
        color: theme.palette.customColors.neutralBrandBlue40
      },

      '& .MuiTablePagination-toolbar': {
        borderTop: getBorder(1)
      }
    }
  }
}

const DataGrid = (theme: Theme) => {
  return {
    MuiDataGrid: {
      styleOverrides: styleOverrides(theme)
    },
    MuiTable: {
      styleOverrides: styleOverridesTable(theme)
    }
  }
}

export default DataGrid
