export const extractIntegerFromString = (str: string) => {
  const match = str.match(/\((\d+)\)/)

  return match ? parseInt(match[1], 10) : ''
}

export const numberShortend = (value: number) => {
  const truncate = (number: number, decimals: number): number => {
    const factor = Math.pow(10, decimals)

    return Math.floor(number * factor) / factor
  }

  if (value >= 1000000) {
    return `${truncate(value / 1000000, 1)}M`
  } else if (value >= 1000) {
    return `${truncate(value / 1000, 1)}K`
  } else {
    return `${truncate(value, 0)}`
  }
}

export const numberWithCommas = (number: number) => {
  if (isNumber(number)) {
    return Math.round(number).toLocaleString()
  }

  return number
}

export const hasSpaces = (value: any) => {
  return typeof value === 'string' && value !== value.trim()
}

export const isFilled = (value: any) => {
  return value !== '' && value !== null && value !== undefined
}

export const isNumberNotString = (value: any) => {
  if (typeof value === 'string') {
    return false
  }

  return isNumber(value)
}

export const isNumber = (value: any) => {
  // "2" -> 2, but "2 " -> should not be "2"

  if (!isFilled(value)) {
    return false
  }

  if (hasSpaces(value)) {
    return false
  }

  if (typeof value === 'string' && value[0] === '.') {
    return false
  }

  if (typeof value === 'string' && value.length > 1 && value[0] === '0' && value[1] !== '.') {
    return false
  }

  return !isNaN(Number(value))
}

export const formatXAxis = (value: any) => {
  return typeof value === 'string' ? value.replace('00:00:00.000', '').replace('00:00:00', '') : value
}

export const formatYAxis = (value: any) => {
  return !isNaN(value) ? numberShortend(value) : value
}

export const formatCost = (value: number) => {
  const number = isNaN(value) ? value : numberShortend(value)

  return `$${number}`
}
