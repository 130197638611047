import { NodeDataStep } from 'src/modules/wfe/wfe.types'
import {
  APPROVE_LABEL,
  ENABLE_RETRIES,
  MAXIMUM_RETRIES,
  NOTIFICATION_PARAMETERS,
  NOTIFICATION_TYPE,
  REJECT_LABEL,
  RETRIES_QUANTITY,
  RETRIES_QUANTITY_TYPE
} from './wfe.constants.aws'
import { NotificationParams } from './components/StepBar/SpecialInputs/ApproveInput'
import { MessageType } from './components/StepBar/SpecialInputs/Teams/types'

export const hasMissingParamsApprove = (node: NodeDataStep) => {
  const parameters = node.step.parameters
  const notificationType = parameters.find(param => NOTIFICATION_TYPE === param.id)?.value

  const approveLabel = parameters.find(parameter => parameter.id === APPROVE_LABEL)?.value
  const rejectLabel = parameters.find(parameter => parameter.id === REJECT_LABEL)?.value
  if (!approveLabel || !rejectLabel) {
    return true
  }

  const enableRetires = parameters.find(param => ENABLE_RETRIES === param.id)?.value
  if (enableRetires) {
    const retriesQuantity = parameters.find(param => RETRIES_QUANTITY === param.id)?.value
    const retriesQuantityType = parameters.find(param => RETRIES_QUANTITY_TYPE === param.id)?.value
    const maximumRetries = parameters.find(param => MAXIMUM_RETRIES === param.id)?.value

    if (!retriesQuantity || !retriesQuantityType || !maximumRetries) {
      return true
    }
  }

  const params = parameters.find(param => param.id === NOTIFICATION_PARAMETERS)?.value as NotificationParams

  if ('EMAIL' === notificationType) {
    if (!params?.recipients || !params?.subject || !params?.body_type || !params?.message) {
      return true
    }
  } else if ('SLACK' === notificationType) {
    if (!params?.integration_id || !params?.channel_id || !params?.message || !params?.header) {
      return true
    }
  } else if ('TEAMS' === notificationType) {
    if (!params?.integration_id || !params?.message) {
      return true
    }

    if (params?.message_type === MessageType.Chat) {
      if (!params?.user_id) {
        return true
      }
    } else if (!params?.team_id || !params?.channel_id) {
      return true
    }
  }

  return false
}
