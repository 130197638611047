import { TriggerValue } from 'src/modules/wfe/components/StepBar/TriggerType/types'
import { dataToBE, request } from './api'

type Trigger = TriggerValue & {
  name: string
  workflowId: string
}

export async function createTrigger(workflowId: string, trigger: Trigger) {
  const response = await request(`triggers/${workflowId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(trigger))
  })

  return response
}

export async function updateTrigger(trigger: Trigger, workflowId: string, triggerId: string) {
  const response = await request(`triggers/${workflowId}/${triggerId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(trigger))
  })

  return response
}

export async function deleteTrigger(workflowId: Trigger, triggerId: string) {
  const response = await request(`triggers/${workflowId}/${triggerId}`, {
    method: 'DELETE'
  })

  return response
}
