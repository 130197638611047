// ** MUI Imports
import { Theme } from '@mui/material/styles'

const Calendar = (theme: Theme) => {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': { borderRadius: '50% !important' },
          '& .MuiButtonBase-root.Mui-selected': {
            backgroundColor: `${theme.palette.primary.main} !important`
          },
          '& .MuiPickersDay-today': {
            borderColor: 'transparent !important'
          }
        }
      }
    }
  }
}

export default Calendar
