// ** React Imports
import { ReactNode, useMemo } from 'react'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'

// ** Type Imports
import { Settings } from 'src/common/context/settingsContext'

// ** Theme Config
import themeConfig from 'src/common/configs/themeConfig'

// ** Direction component for LTR or RTL
import Direction from 'src/components/layouts/components/Direction'

// ** Theme Override Imports
import overrides from './overrides'
import typography from './typography'

// ** Theme
import themeOptions from './ThemeOptions'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true
  }
}

interface Props {
  settings: Settings
  children: ReactNode
}

const objectToCssVars = (obj: object, prefix = '') => {
  let cssVars = ''

  for (const key in obj) {
    if (obj[key as keyof object]) {
      const value = obj[key as keyof object]
      const variableName = prefix ? `-${prefix}-${key}` : `--${key}`

      if (typeof value === 'object') {
        // Recursively process nested objects
        cssVars += objectToCssVars(value, `${prefix}-${key}`)
      } else if (typeof value === 'string' && !String(value).includes('function')) {
        cssVars += `${variableName}: ${value};\n`
      }
    }
  }

  return cssVars
}

const ThemeComponent = (props: Props) => {
  // ** Props
  const { settings, children } = props

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings)
  let theme = createTheme(coreThemeConfig)

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) => typography(theme)

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    typography: { ...mergeTypography(theme) },
    components: overrides(theme)
  })

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  const cssVars = useMemo(() => objectToCssVars(theme.palette), [theme])

  return (
    <ThemeProvider theme={theme}>
      <style>
        {`:root{
          ${cssVars}
        }`}
      </style>
      <Direction direction={settings.direction}>
        <CssBaseline />
        {children}
      </Direction>
    </ThemeProvider>
  )
}

export default ThemeComponent
