import React from 'react'
import cx from 'classnames'
import { strings } from '../../common/constants/strings'
import { Flex } from '../styled-components/flex'
import Icon from '../Icon'
import styles from './index.module.css'

type CopyErrorProps = {
  title: string
  description: string
  className?: string
}

const CopyError = ({ className = '', title, description }: CopyErrorProps) => (
  <div className={cx(className, styles.copyError)}>
    <Flex className={styles.ErrorTitle}>
      <div className={styles.colorDot} />
      <div className={styles.title}>{title}</div>
    </Flex>
    {description && (
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>{description}</div>
        <Icon
          className={styles.copyIcon}
          tooltipProps={{ title: strings.common.copy }}
          name='copy'
          height={24}
          width={24}
          hoverColor='var(--neutralBrandBlue60)'
          color='var(--neutralBrandBlue40)'
          onClick={() => {
            navigator.clipboard.writeText(description)
          }}
        />
      </div>
    )}
  </div>
)

export default CopyError
