import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Spinner } from 'src/components/spinner'
import { arrayToString, stringToArray } from 'src/common/utils/str.util'
import { Workflow } from 'src/common/types/common.types'

export const WorkflowCreateTemplateDialog = ({
  isCreateTemplateShow,
  isTemplateCreating,
  workflow,
  onCreateTemplate,
  onClose
}: {
  isCreateTemplateShow: boolean
  isTemplateCreating: boolean
  workflow: Workflow
  onCreateTemplate: (template: any) => void
  onClose: () => void
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [labels, setLabels] = useState<Array<string>>([])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleCreateClick = () => {
    onCreateTemplate({ name, description, labels })
  }

  const checkEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && name) {
      handleCreateClick()
    }
  }

  useEffect(() => {
    setName(workflow?.name ?? '')
    setDescription(workflow?.description ?? '')
    setLabels(workflow?.labels ?? [])
  }, [workflow])

  return (
    <Dialog open={isCreateTemplateShow} onClose={onClose}>
      <DialogTitle>Create Template</DialogTitle>
      <DialogContent sx={{ width: '450px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <br />

        <TextField
          autoFocus
          id='templateName'
          label='Template Name'
          type='text'
          fullWidth
          value={name}
          onChange={handleInputChange}
          onKeyDown={e => checkEnter(e)}
        />
        <TextField
          label='Description'
          type='text'
          fullWidth
          multiline
          rows={8}
          value={description}
          onChange={e => setDescription(e.target.value)}
          onKeyDown={e => checkEnter(e)}
        />
        <TextField
          label='Labels'
          type='text'
          placeholder='label1,label2,label3'
          fullWidth
          value={arrayToString(labels ?? [])}
          onChange={e => setLabels(stringToArray(e.target.value).map(lab => lab.trim()))}
          onKeyDown={e => checkEnter(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant={'contained'} disabled={!name} onClick={handleCreateClick}>
          Create {isTemplateCreating && <Spinner size='small' variant='secondary' />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
