// ** MUI Imports
import { Theme } from '@mui/material/styles'

const List = (theme: Theme) => {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          '& .MuiListItemIcon-root': {
            minWidth: '32px'
          },

          '& button': {
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            borderRadius: 0,
            justifyContent: 'flex-start',
            fontSize: '0.9rem !important',
            color: `${theme.palette.secondary.dark} !important`
          },
          '&[role="menu"]': {
            color: `${theme.palette.grey[300]} !important`,
            '& svg': { color: `${theme.palette.grey[300]} !important` }
          }
        }
      }
    }
  }
}

export default List
