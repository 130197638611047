import { useMutation, useQueryClient } from 'react-query'
import { wivCaptureException } from 'src/common/utils/sentry'
import { updateWorkflowVersionAttributes, VersionAttributes } from 'src/services/workflows.api'

export const useUpdateWorkflowVersionAttributes = (wfid: string) => {
  const queryClient = useQueryClient()
  const { isLoading, mutate } = useMutation(
    ({ versionId, attributes }: { versionId: string; attributes: VersionAttributes }) =>
      updateWorkflowVersionAttributes(wfid, versionId, attributes),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workflows/versions', wfid])
      },
      onError: wivCaptureException
    }
  )

  return { isLoading, mutate }
}
