import { useMutation, useQueryClient } from 'react-query'
import { shallow } from 'zustand/shallow'
import { wivCaptureException } from 'src/common/utils/sentry'
import { updateWorkflowVersion } from 'src/services/workflows.api'
import useStore from '../wfe.store'

const selector = (state: any) => ({
  clearWorkflow: state.clearWorkflow
})

export const useSetWFVersion = (wfid?: string) => {
  const queryClient = useQueryClient()
  const { clearWorkflow } = useStore(selector, shallow)
  const { isLoading, mutate } = useMutation((versionId: string) => updateWorkflowVersion(wfid!, versionId), {
    onSuccess: () => {
      // basically refetch workflow
      clearWorkflow()
      queryClient.invalidateQueries(['workflows/versions', wfid])
    },
    onError: wivCaptureException
  })

  return { isLoading, mutate }
}
