// ** MUI Imports
import { Theme } from '@mui/material/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Chip = (theme: Theme) => {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: `${theme.palette.grey[500]} !important`,
          border: `1px solid ${theme.palette.grey[200]} !important`,
          backgroundColor: `${theme.palette.grey[50]} !important`
        }
      }
    }
  }
}

export default Chip
