import { UNPUBLISHED, Workflow } from 'src/common/types/common.types'
import { request } from './api'

export async function fetchWorkflows() {
  const response = await request(`workflows`)

  return response.data.map((workflow: { state: any }) => ({ ...workflow, state: workflow.state ?? UNPUBLISHED }))
}

export async function fetchWorkflow(wfId: string) {
  return await request(`workflows/${wfId}`)
}

export async function fetchWorkflowLastExecutions(wfId: string) {
  return await request(`workflows/${wfId}/steps/last_executions`)
}

export async function fetchWorkflowStepTestExecutions(wfId: string) {
  return await request(`workflows/${wfId}/steps/test_executions`)
}

export async function fetchWorkflowVersions(wfId: string) {
  return await request(`workflows/${wfId}/versions`)
}

export async function fetchWorkflowVersion(wfId: string, versionId: string) {
  return await request(`workflows/${wfId}/versions/${versionId}`)
}

export async function fetchWorkflowLastExecutionsByStep(wfId: string, stepName: string, stepPath = '') {
  return await request(`workflows/${wfId}/steps/${stepName}/last_execution`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ stepPath })
  })
}

export async function writeWorkflow(wfObj: any, fill_integrations = false) {
  const url = fill_integrations ? `workflows?fill_integrations=${fill_integrations}` : 'workflows'

  const { inputParameters, ...rest } = wfObj
  const wfToBE = { ...rest, input_parameters: inputParameters }

  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(wfToBE)
  })

  return response
}

export async function updateWorkflowVersion(wfId: string, versionId: string) {
  return await request(`workflows/${wfId}/versions/${versionId}/set-latest`, {
    method: 'PUT'
  })
}

export type VersionAttributes = {
  creator?: string
  name?: string
}

export async function updateWorkflowVersionAttributes(wfId: string, versionId: string, attributes: VersionAttributes) {
  return await request(`workflows/${wfId}/versions/${versionId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(attributes)
  })
}

export async function updateWorkflow(wfId: string, wfObj: any) {
  const response = await request(`workflows/${wfId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(wfObj)
  })

  return response
}

export async function updateWorkflowPartial(wfId: string, wfObj: any) {
  const response = await request(`workflows/${wfId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(wfObj)
  })

  return response
}

export type BulkUpdatesWorkflows = { workflowId: string; properties: Partial<Workflow> }[]

export async function bulkUpdateWorkflows(updates: BulkUpdatesWorkflows) {
  try {
    const response = await request('workflows/bulk-update', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updates)
    })

    return response
  } catch (error) {
    console.error('Error updating workflows:', error)
    throw error
  }
}

export async function deleteWorkflow(wfId: string) {
  const response = await request(`workflows/${wfId}`, {
    method: 'DELETE'
  })

  return response
}

export async function cloneWorkflow(wfId: string) {
  const response = await request(`workflows/copy/${wfId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })

  return response
}

export async function createWorkflowTriggerEvent(wfId: string, triggerId: string, payload?: any) {
  const triggerEvent = {
    payload: payload ?? {}
  }
  const response = await request(`workflows/${wfId}/triggers/${triggerId}/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(triggerEvent)
  })

  return response
}

export async function createWorkflowSingleExecutionStep(wfId: string, step: any) {
  const stepName = encodeURI(step.name)
  const response = await request(`workflows/${wfId}/steps/${stepName}/executions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(step)
  })

  return response
}

export async function getWorkflowSingleExecutionStep(wfId: string, stepName: string, executionId: string) {
  const response = await request(`workflows/${wfId}/steps/${encodeURI(stepName)}/executions/${executionId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return response
}

export async function stopWorkflow(wfId: string, executionId: string) {
  const response = await request(`workflows/${wfId}/executions/${executionId}/stop`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })

  return response
}

export async function getAWSTimeZones() {
  return await request(`workflows/aws/time-zones`)
}

export async function getAWSRegions() {
  return await request(`workflows/aws/regions`)
}

export async function postGenerateWorkflowGetToken(prompt = '') {
  if (!prompt) {
    return null
  }

  const response = await request(`workflows/wf_llm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ prompt })
  })

  return response.data
}

export const getGeneratedWorkflowFromToken = async (token: string) => {
  return await request(`workflows/wf_llm/${token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
