export const getStepSuggestions = ({
  currentStepExecutions,
  stepsBefore
}: {
  currentStepExecutions: any
  stepsBefore: Array<string>
}) => {
  if (!Object.keys(currentStepExecutions).length) {
    return null
  }

  // TODO: add more suggestions , like:
  // When inside loop => the loop itself / collector
  // When inside collector => the loop itself

  const lastTwoSteps = stepsBefore.slice(-2)

  return Object.keys(currentStepExecutions).reduce((acc: any, stepName: string) => {
    if (lastTwoSteps.includes(stepName)) {
      acc[stepName] = currentStepExecutions[stepName]
    }

    return acc
  }, {})
}
