import Graph from 'graphology'
import { LoopResultType } from '../../modules/wfe/wfe.constants'
import { NodeSubType } from '../constants/common.constants'

export const isInsideLoop = (
  graph: Graph,
  nodeId: string,
  currentNodeId = null,
  foundClose = false,
  visited = new Set()
) => {
  if (visited.has(nodeId)) {
    return { insideLoopScope: false, scopeParentId: null }
  }

  visited.add(nodeId)
  const attributes = graph.getNodeAttributes(nodeId)

  if (attributes.data.loopResultType === LoopResultType.Close) {
    foundClose = true
    currentNodeId = attributes.id
  } else if (attributes.data.subtype === NodeSubType.Loop) {
    if (!foundClose && attributes.id !== currentNodeId) {
      return { insideLoopScope: true, scopeParentId: attributes.id }
    }

    // Reset foundClose and currentNodeId when the second condition is met
    foundClose = false
    currentNodeId = null
  }

  let insideLoopScope = false
  let scopeParentId = null
  graph.forEachInNeighbor(nodeId, source => {
    const result = isInsideLoop(graph, source, currentNodeId, foundClose, visited)
    if (result.insideLoopScope) {
      insideLoopScope = true
      scopeParentId = result.scopeParentId

      return false 
    }
  })

  return { insideLoopScope, scopeParentId }
}
