import Graph from 'graphology'
import { Edge, Node } from 'reactflow'
import {
  NodeScope,
  NodeSubType,
  NodeType,
  StepGroupType,
  StepSubGroupType,
  StepType
} from 'src/common/constants/common.constants'

export type NodeData = {
  type: NodeType
  subtype: NodeSubType
  scope: NodeScope
  id: string
  comment?: string
  data?: any
}

export interface NodeDataStep extends NodeData {
  step: Step
}

export type Parameter = {
  id: string
  value: string
  title?: string
  defaultValue?: string
  type?: string
  valueToUI?: any
  description?: string
  optional?: boolean
}

export interface Step {
  type: StepType
  group: StepGroupType
  subgroup: StepSubGroupType
  stepTemplateId: string
  name: string
  title: string
  description: string
  parameters: Parameter[]
}

export interface IWorkflowModel {
  getGraph(): Graph

  addNode(targetPlaceholderId: string, newNodeData: any): void

  removeNode(nodeId: string): void

  getNodes(): any[]

  getEdges(): any[]
}

export type StepParametersStatus = 'fresh' | 'outdated' | 'missing' | 'loading' | 'error'
export type StepParametersStatusAction = 'unFocus' | 'changeParams' | 'error' | 'loading' | 'timeout'

export enum SingleStepExecutionsStatuses {
  'RUNNING' = 'RUNNING',
  'FAIL' = 'FAIL',
  'OK' = 'OK',
  'TIMEOUT' = 'TIMEOUT'
}

export type SingleStepExecutionsStatus = keyof typeof SingleStepExecutionsStatuses

export type LastExecution = {
  stepName: string
  timestamp: string
  stepExecutionId: string
  workflowId: string
  output?: any
  status: SingleStepExecutionsStatus
}

export type Execution = {
  id?: string
  output?: object
  status?: string
  parametersStatus?: StepParametersStatus
  timestamp: string
  stepExecutionId: string
  workflowId: string
  displayStep?: boolean
  isStopped?: boolean
}

export enum InsertedVariableType {
  Text = 'text',
  Label = 'label'
}

export type HistoryItem = {
  nodes: Node[]
  edges: Edge[]
}

export enum WorkflowMode {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}
