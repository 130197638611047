// ** React Imports
import { AuthProvider } from '@propelauth/nextjs/client'
import { Router, useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import * as Sentry from '@sentry/react'

// ** Loader Import
import NProgress from 'nprogress'

// ** Config Imports
import { LicenseInfo } from '@mui/x-license-pro'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

// ** Global css styles
import '../../styles/globals.css'
import { ReactNode, useEffect } from 'react'
import themeConfig from 'src/common/configs/themeConfig'
import type { NextPage } from 'next'

import { PrivateRoutes } from 'src/common/auth/PrivateRoutes'
import { WithTheme } from '../common/theme/WithTheme'
import Error500 from './500'
import './app.css'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PRO_LICENSE ?? '')

// ** Extend App Props with Emotion
interface ExtendedAppProps extends AppProps {
  Component: NextPage
}

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
    window.Intercom('update')
  })
}

const ConditionalSentryErrorBoundary = ({ children }: { children: ReactNode }) => {
  if (process.env.IS_LOCALHOST === 'true') {
    return <>{children}</>
  } else {
    return <Sentry.ErrorBoundary fallback={Error500}>{children}</Sentry.ErrorBoundary>
  }
}

// ** Configure JSS & ClassName
function App(props: ExtendedAppProps) {
  const { Component, pageProps } = props

  const authUrl = process.env.NEXT_PUBLIC_AUTH_URL ? process.env.NEXT_PUBLIC_AUTH_URL : ''

  const router = useRouter()

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ConditionalSentryErrorBoundary>
      <PostHogProvider client={posthog}>
        <AuthProvider authUrl={authUrl}>
          <PrivateRoutes>{WithTheme(Component, pageProps)}</PrivateRoutes>
        </AuthProvider>
      </PostHogProvider>
    </ConditionalSentryErrorBoundary>
  )
}

export default App
