import { Tooltip } from '@mui/material'
import { GridApiPro } from '@mui/x-data-grid-pro'
import { MutableRefObject } from 'react'
import { GridApiCommunity } from '@mui/x-data-grid/internals'

export const UNEXPORTED_COLUMNS = ['action']
export const MIN_COLUMN_WIDTH = 125

export const renderObject = (value: object) => {
  const valueObject = JSON.stringify(value)

  return (
    <Tooltip title={valueObject}>
      <span>{valueObject}</span>
    </Tooltip>
  )
}

export type DownloadGridFileType = {
  dataKeys: Array<string>
  data: Array<any>
  title: string
  type?: 'csv' | 'json'
}
export const downloadGridFile = ({ dataKeys, data, title, type = 'csv' }: DownloadGridFileType) => {
  let csvContent = 'data:text/csv;charset=utf-8,'

  if (type === 'csv') {
    csvContent += dataKeys.join(',') + '\n'

    data.forEach((row: { [x: string]: any }) => {
      const rowData = dataKeys.map(col => row[col])
      csvContent += rowData.join(',') + '\n'
    })
  } else {
    csvContent += JSON.stringify(data)
  }

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${title}.${type}`)
  document.body.appendChild(link)

  link.click()
}

export const downloadGridFilePro = ({
  data,
  title,
  type = 'csv',
  columns,
  state
}: {
  data: Array<any>
  title: string
  type?: 'csv' | 'json'
  columns: Array<any>
  state: any
}) => {
  const sortedDataKeys = columns
    .filter(
      column =>
        !UNEXPORTED_COLUMNS.includes(column.field) && state?.columns?.columnVisibilityModel[column.field] !== false
    )
    .map(column => column.field)
    .sort((a, b) => state?.columns?.orderedFields.indexOf(a) - state?.columns?.orderedFields.indexOf(b))

  downloadGridFile({
    dataKeys: sortedDataKeys,
    data,
    title,
    type
  })
}

export const downloadRowById = (
  apiRef: MutableRefObject<GridApiPro> | MutableRefObject<GridApiCommunity>,
  rowId: string | number,
  title: string
) => {
  const row = apiRef.current?.getRow(rowId)

  if (!row) {
    console.error('Row not found')

    return
  }

  const headers = Object.keys(row).join(',')
  const values = Object.values(row).join(',')
  const csvContent = `${headers}\n${values}`

  const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent)

  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${title}-${rowId}.csv`)

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadGridCSV = ({
  apiRef,
  title
}: {
  apiRef: MutableRefObject<GridApiPro> | MutableRefObject<GridApiCommunity>
  title: string
}) => {
  apiRef.current.exportDataAsCsv({ fileName: title })
}

export const PINNED_STYLE = {
  '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
    backgroundColor: '#fff',
    boxShadow: 'none'
  }
}

export const getVisibleRows = ({
  searched,
  paginationModel,
  id = 'id'
}: {
  searched: Array<any>
  paginationModel: { page: number; pageSize: number }
  id?: string
}) => {
  return searched
    ?.slice(paginationModel.page * paginationModel.pageSize, (paginationModel.page + 1) * paginationModel.pageSize)
    .map(item => item[id])
}

export const getIsAllSelected = ({
  data,
  visibleRows,
  selectedRows,
  tab
}: {
  data: any[]
  visibleRows: string[]
  selectedRows: any
  tab?: string
}) =>
  !!(
    data.length &&
    visibleRows.every((id: string) => {
      return (tab ? selectedRows[tab] : selectedRows).includes(id)
    })
  )
