import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { SNACKBAR_PROPS } from '../constants/ui-components.constants'
import { wivCaptureException } from '../utils/sentry'

interface NotificationContextProps {
  notify: (message: any, severity?: 'success' | 'error' | 'warning' | 'info') => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info')

  const notify = useCallback((message: string, severity: 'success' | 'error' | 'warning' | 'info' = 'info') => {
    setMessage(message)
    setSeverity(severity)
  }, [])

  const handleClose = useCallback((_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setMessage('')
  }, [])

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <Snackbar open={!!message} onClose={handleClose} {...SNACKBAR_PROPS}>
        <Alert
          sx={{ alignItems: 'center', '& .MuiAlert-action': { paddingTop: 0 } }}
          variant='filled'
          onClose={handleClose}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

export const useNotify = () => {
  const context = useContext(NotificationContext)

  if (!context) {
    return (message: any, severity?: 'success' | 'error' | 'warning' | 'info') => {
      wivCaptureException('useNotify must be used within a NotificationProvider: ' + message + ' ' + severity)

      severity === 'error' ? console.error(message) : console.log(message)
    }
  }

  return context.notify
}
