import { useQuery } from 'react-query'
import { shallow } from 'zustand/shallow'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { getGithubWorkflowInputs } from 'src/services/integrations.api'
import { parseOutputValue } from '../wfe/components/StepBar/outputTree.utils'
import { Parameter } from '../wfe/wfe.types'
import { GITHUB_REPO_PARAM, GITHUB_INTEGRATION_PARAM, GITHUB_WORKFLOW_PARAM } from '../wfe/wfe.constants'
import useStore from '../wfe/wfe.store'

export type WorkflowInputData = {
  description: string
  required: boolean
  default?: string
  options?: string[]
  type?: string
}
export type WorkflowInput = Record<string, WorkflowInputData>

export const mapWorkflowInputs = (data?: WorkflowInput) =>
  Object.entries(data ?? {}).map(([key, value]) => {
    const inputData = { ...value }
    if (Object.prototype.hasOwnProperty.call(inputData, 'options')) {
      inputData.options = inputData.options?.map(option => ({ value: option, label: option })) as any
    }

    return {
      id: key,
      ...inputData
    }
  })

const useGithubWorkflowInputsQuery = (integrationId: string, repoId: string, workflowId: string) => {
  const { data, isLoading } = useQuery<WorkflowInput>(
    ['github-workflow-inputs', workflowId],
    async () => getGithubWorkflowInputs(integrationId, repoId, workflowId),
    {
      staleTime: LONG_STALE_TIME,
      enabled: !!integrationId && !!repoId && !!workflowId
    }
  )

  return { data, isLoading }
}

const selector = (state: any) => ({
  githubRepoId: parseOutputValue(
    state.focusedNode.step?.parameters.find((parameter: Parameter) => parameter.id === GITHUB_REPO_PARAM)?.value
  ),
  integrationId: parseOutputValue(
    state.focusedNode.step?.parameters.find((parameter: Parameter) => parameter.id === GITHUB_INTEGRATION_PARAM)?.value
  ),
  githubWorkflowId: parseOutputValue(
    state.focusedNode.step?.parameters.find((parameter: Parameter) => parameter.id === GITHUB_WORKFLOW_PARAM)?.value
  )
})

export const useGithubWorkflowInputs = () => {
  const { githubWorkflowId, integrationId, githubRepoId } = useStore(selector, shallow)
  const { data, isLoading } = useGithubWorkflowInputsQuery(integrationId, githubRepoId, githubWorkflowId)

  return { data, isLoading }
}
