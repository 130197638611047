import { InputParams } from 'src/common/utils/types'
import { FlowActionsSubgroups } from '../../components/AddNodeModal/utils'
import { mapParamsToUi, mapValueToCoreParameter } from './wfe.stepParameterMapper'

export default class WfeSteps {
  private static instance: WfeSteps
  public stepsGroups: any
  private steps: Record<string, InputParams[]>

  private constructor() {
    this.steps = {}
    this.stepsGroups = {}
  }

  public static getInstance(): WfeSteps {
    if (!WfeSteps.instance) {
      WfeSteps.instance = new WfeSteps()
    }

    return WfeSteps.instance
  }

  public setSteps(steps: any): void {
    const actionsMap: any = {}
    Object.entries(steps).forEach(([key, value]) => {
      const step = value as any
      actionsMap[step.subgroup] = actionsMap[step.subgroup] || []
      const stepData = { ...step }
      delete stepData.parameters
      actionsMap[step.subgroup].push({
        stepTemplateId: key,
        group: Object.entries(FlowActionsSubgroups)
          .find(([, subGroups]: any) => subGroups.includes(step.subgroup))?.[0]
          ?.toUpperCase(),
        ...stepData
      })
    })
    this.stepsGroups = actionsMap

    const mappedSteps = Object.entries(steps).reduce((acc, [key, stepData]) => {
      // @ts-ignore
      const parameters = (stepData.parameters as Record<string, any>[]).map((parameter: Record<string, any>) => {
        if ((parameter as any).valueToCoreFlag) {
          parameter = mapValueToCoreParameter(parameter as any)
        }

        if ((parameter as any).parametersToUIBase) {
          parameter = mapParamsToUi(parameter as any)
        }

        return parameter
      })

      return {
        ...acc,
        [key]: parameters
      }
    }, {})

    this.steps = mappedSteps
  }

  public getSteps() {
    return this.steps
  }

  public getActions() {
    return this.stepsGroups
  }

  public getSubGroupActions(subgroup: string): any {
    const subGroupActions = this.stepsGroups[subgroup] ?? []
    const actions = subGroupActions.filter((action: any) => this.steps[action.stepTemplateId])

    return actions
  }
}
