import { styled } from '@mui/material/styles'

interface FlexProps {
  alignItems?: string
  justifyContent?: string
  gap?: any
  width?: string | number
}

export const Flex = styled('div')<FlexProps>(
  ({ alignItems = 'center', justifyContent = 'normal', gap = undefined, width }) => ({
    display: 'flex',
    alignItems,
    justifyContent,
    gap,
    width
  })
)

export const InlineFlex = styled('div')<FlexProps>(
  ({ alignItems = 'center', justifyContent = 'normal', gap = undefined }) => ({
    display: 'inline-flex',
    alignItems,
    justifyContent,
    gap
  })
)
