export enum TriggerTypes {
  'SCHEDULED' = 'SCHEDULED',
  'MANUAL' = 'MANUAL',
  'INTEGRATION_EVENT' = 'INTEGRATION_EVENT'
}

export enum ScheduleTypes {
  'CRON' = 'CRON',
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'INTERVAL' = 'INTERVAL'
}

export enum IntervalTypes {
  'MINUTES' = 'MINUTES',
  'HOURS' = 'HOURS',
  'DAYS' = 'DAYS',
  'MONTHS' = 'MONTHS'
}

export type TriggerConfiguration = {
  scheduleType?: keyof typeof ScheduleTypes
  cronExpression?: string
  timezone?: string
  minute?: number
  hour?: number
  dayOfWeek?: string
  dayOfMonth?: string
  number?: number
  intervalType?: keyof typeof IntervalTypes
  eventId?: string
  integrationId?: string
}

export type TriggerInputParameterTypes = 'string' | 'number' | 'boolean' | 'list' | 'object'

export type TriggerInputParameter = {
  id: string
  recordId: string
  type: TriggerInputParameterTypes
  defaultValue?: any
  description?: string
  optional?: boolean
}

export type TriggerValue = {
  type: keyof typeof TriggerTypes
  configuration: TriggerConfiguration
  triggerId?: string
  inputParameters?: Array<TriggerInputParameter>
}
